import { useState, useEffect } from 'react';
// -- Primereact Libs
import { classNames } from 'primereact/utils';
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
// -- ANTD Libs
import { Calendar, CalendarProps, Col, Radio, Row, Select } from 'antd';
// -- Other Libs
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import dayLocaleData from 'dayjs/plugin/localeData';
// ------
// TODO New struct

import { Active } from "../../../models";
import { useEvents } from "../../../hooks/modules/commons";
import { useTcmSupervisios } from "../../../hooks/modules/tcm";
// -- Component
import { SelectedDayTcms } from "./components";

dayjs.extend(dayLocaleData);


const getMonthData = (value: Dayjs) => {
  if (value.month() === 2) {
    return 48;
  }
  if (value.month() === 1) {
    return 35;
  }
  if (value.month() === 0) {
    return 39;
  }
};

function formatDate(date: Date) {
  let day = ('0' + date.getDate()).slice(-2);
  let month = ('0' + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear();

  return month + '/' + day + '/' + year;
}

const TcmsDashboard = ({ active, relad }: Props) => {
  // TODO -- Listar los dias de las supervisones de cada TCM
  const { tcmSupervisons } = useTcmSupervisios({ id: active?.activeUser?.User?.ID.toString() ?? "0" });
  const { myEvents,isLoadingEvents, reloadMyEvents } = useEvents();
  const [visible, setVisible] = useState<boolean>(false);
  const [dateSelect, setDateSelect] = useState<string>("");

  // TODO: Crear filtro para saber cuantas notas se hace por mes
  const monthCellRender = (value: Dayjs) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Notes</span>
      </div>
    ) : null;
  };

  const dateCellRender = (value: Dayjs) => {
    const current = dayjs().format('MM/DD/YYYY');

    const dates = value.format("MM/DD/YYYY");
        
    let numEvents = 0;
    let numReminders = 0;
    
    const renderedEvents = myEvents?.all_event.map((item) => {
      if (dates === item.date) {
        numEvents++;
        return (

          <li key={item.ID} className='flex'>
            <Tooltip target={`.custom-target-event-${item.ID}`} />
            <i
              className={classNames(
                `custom-target-event-${item.ID}`,
                "pi pi-flag flex text-gray-500"
              )}
              // style={{ color: dates < (active?.activeUser?.WeekActive?.start ?? "") ? 'grey' : dates > (active?.activeUser?.WeekActive?.end ?? "") ? 'orange' : 'blue' }}
              data-pr-tooltip={`${item.description}`}
              data-pr-position="top"
            />
            {dates < (active?.activeUser?.WeekActive?.start ?? "") ? (
              <s className='pl-2'>{item.title}</s>
            ) : dates > (active?.activeUser?.WeekActive?.end ?? "") ? (
              <i className='pl-2'>{item.title}</i>
            ) : (
              <span className='pl-2'>{item.title}</span>
            )}
          </li>
        );
      }
      return null;
    });

    const renderedReminders = tcmSupervisons?.supervisions?.map((item) => {
      let datesReminders = new Date(item.date);

      if (dates === formatDate(datesReminders)) {
        numReminders++;
        let palabras = item.title.split(" ");
        let primerasDosPalabras = palabras.slice(0, 1).join(" ");
        return (
          <>
            <Tooltip target={`.custom-target-reminders-${item.id}`} />
            <li key={item.id}
              className={`custom-target-reminders-${item.id} flex`}
              data-pr-tooltip={`${item.title}`}
              data-pr-position="top"
            >

              <i
                className={classNames(
                  "pi pi-file-check flex text-gray-500"
                )}
              // style={{ color: dates < (active?.activeUser?.WeekActive?.start ?? "") ? 'grey' : dates > (active?.activeUser?.WeekActive?.end ?? "") ? 'orange' : 'blue' }}

              />
              {/* {dates < (active?.activeUser?.WeekActive?.start ?? "") ? (
              <s className='pl-2'>{item.title}</s>
            ) : dates > (active?.activeUser?.WeekActive?.end ?? "") ? (
              <i className='pl-2'>{item.title}</i>
            ) : ( */}
              <span className='pl-2'>Supervison {primerasDosPalabras}...</span>
              {/* )} */}
            </li>
          </>
        );
      }
      return null;
    });

    const combineAndAlternate = (arr1: JSX.Element[], arr2: JSX.Element[]) => {
      const result: JSX.Element[] = [];
      for (let i = 0; i < Math.max(arr1.length, arr2.length); i++) {
        if (i < arr1.length) {
          result.push(arr1[i]);
        }
        if (i < arr2.length) {
          result.push(arr2[i]);
        }
      }
      return result;
    };

    const safeRenderedEvents = (renderedEvents || []).flat().filter((item): item is JSX.Element => item !== null && item !== undefined);
    const safeRenderedReminders = (renderedReminders || []).flat().filter((item): item is JSX.Element => item !== null && item !== undefined);

    const combinedEvents = combineAndAlternate(safeRenderedEvents, safeRenderedReminders);

    return (
      <div
        className={classNames(
          'h-36 p-1 pt-0 border-t hover:bg-gray-100',
          'p-ripple',
        )}
        onClick={() => { onSelect(value) }}
      >
        {/* TODO: Modificar estructura de calencario */}
        <div className={classNames(
          'w-full shadow-lg',
          // dates === dateSelect ? 'bg-green-100' : numNotes > 0 ? 'bg-yellow-100' : numEvents > 0 && 'bg-yellow-100',
          dates === dateSelect ? 'bg-green-100' : (
            ((dates >= (active?.activeUser?.WeekActive?.start ?? "")) && (dates <= (active?.activeUser?.WeekActive?.end ?? ""))) ? 'bg-blue-100' :
              numEvents > 0 ? 'bg-yellow-100' : numReminders > 0 && 'bg-yellow-100'
          ),
        )}>
          <div className={classNames(
            'w-full flex shadow-sm text-center pt-2'
          )}>
            <div className='w-1/2 text-right flex'>
              
              {numEvents > 0 &&
                <div className='pl-2'>
                  {numEvents.toString()}
                  <i
                    className={classNames(
                      'pi pi-flag'
                    )}
                  />
                </div>
              }

            </div>
            <div className='text-center'>
              {/* TODO: Aqui va la chincha*/}
              {(numEvents) > 0 &&
                // <div className={classNames(
                //   'border-2 border-red-500 rounded-full p-0 w-7 bg-red-700 shadow-lg',
                //   'transition-opacity duration-1000 delay-5000 ease-out opacity-0'
                // )}>
                <i className='pi pi-thumbtack' style={{ position: 'relative', top: "-5px", transform: "rotate(30deg)" }} />
                // </div>
              }
            </div>
            <div className='w-1/2 text-right justify-content-center'>
              {numReminders > 0 && current <= dates && (new Date(dates).getMonth() === new Date(current).getMonth()) && (
                <>
                  <Tooltip target={`.custom-target-eye-${dates.replace(/\//g, '')}`} />
                  <i
                    className={classNames(
                      'custom-target-eye-' + dates.replace(/\//g, ''),
                      'pi pi-eye pr-1',
                      'animate-blink'
                    )}
                    data-pr-tooltip="Reminders"
                    data-pr-position="left"
                  />
                </>

              )}
              <Badge value={dates.split("/", 2)[1]} className={classNames(
                dates === current ? 'bg-primary' : dates === dateSelect ? 'bg-gray-300 text-primary' : dates.split("/", 2)[0] !== current.split("/", 2)[0] ? 'bg-transparent text-gray-300' : 'bg-transparent text-primary'
              )}></Badge>
            </div>
          </div>
          <div className={classNames(
            'w-full text-left pl-1 overflow-y-auto h-24',
          )}>
            {isLoadingEvents ? (
              <>
                <Skeleton className="mb-2"></Skeleton>
                <Skeleton width="10rem" className="mb-2"></Skeleton>
                <Skeleton width="5rem" className="mb-2"></Skeleton>
              </>
            ) : (
              <ul>
                {combinedEvents}
              </ul>
            )}

          </div>


        </div>
        <Ripple
          pt={{
            root: { style: { background: 'rgba(255, 193, 6, 0.3)' } }
          }}
        />
      </div >
    )
  };

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };

  const onSelect = (value: Dayjs) => {
    const dates = value.format("MM/DD/YYYY");
    setDateSelect(dates);
    setVisible(true);
  };


  useEffect(() => {
    // -- Recargar los datos
    reloadMyEvents();

    if (typeof document !== 'undefined') {
      // Ahora puedes utilizar el objeto 'document' de manera segura
      let elements = Array.from(document.querySelectorAll('.transition-opacity'));

      // Mezcla el array de elementos
      elements = elements.sort(() => Math.random() - 0.1);

      let i = 0;

      const interval = setInterval(() => {
        if (i >= elements.length) {
          clearInterval(interval);
        } else {
          elements[i].classList.remove('opacity-0');
          elements[i].classList.add('opacity-100');
          i++;
        }
      }, 300);

      return () => clearInterval(interval);
    }
  }, [relad]);



  return (
    <div className="card w-full lg:card-side ">
      <Calendar
        className='bg-gray-50'
        mode='month'
        fullscreen={true}
        headerRender={({ value, type, onChange, onTypeChange }) => {
          const start = 0;
          const end = 12;
          const monthOptions: JSX.Element[] = [];

          let current = value.clone();
          const localeData = value.localeData();
          const months: JSX.Element[] = [];
          for (let i = 0; i < 12; i++) {
            current = current.month(i);
            months.push(<span>{localeData.monthsShort(current)}</span>);
          }


          for (let i = start; i < end; i++) {
            monthOptions.push(
              <Select.Option key={i} value={i} className="month-item">
                {months[i]}
              </Select.Option>,
            );
          }

          const year = value.year();
          const month = value.month();
          const options: JSX.Element[] = [];
          for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
              <Select.Option key={i} value={i} className="year-item">
                {i}
              </Select.Option>,
            );
          }
          return (
            <div className='w-full flex'>
              <div className='w-1/2 pt-5 pl-5 flex'>
                <b>Select the date:</b>
                <Row gutter={8}>
                  <Col>
                    <Radio.Group
                      size="small"
                      onChange={(e) => onTypeChange(e.target.value)}
                      value={type}
                    >
                      {/* <Radio.Button value="month">Month</Radio.Button> */}
                      {/* <Radio.Button value="year">Year</Radio.Button> */}
                    </Radio.Group>
                  </Col>
                  <Col>
                    <Select
                      size="small"
                      className="my-year-select"
                      value={year}
                      onChange={(newYear) => {
                        const now = value.clone().year(newYear);
                        onChange(now);
                      }}
                    >
                      {options}
                    </Select>
                  </Col>
                  <Col>
                    <Select
                      size="small"

                      value={month}
                      onChange={(newMonth) => {
                        const now = value.clone().month(newMonth);
                        onChange(now);
                      }}
                    >
                      {monthOptions}
                    </Select>
                  </Col>
                </Row>

              </div>
              <div className='w-1/2 text-right pr-5 pt-5 items-center'>
                <b>Legend:</b>
                {active?.activeUser?.User?.roll === "TCM" && (<><i className='pi pi-file-edit pl-3' /> Notes</>)}
                <i className='pi pi-flag pl-3' /> Events
                {/* <i className='pi pi-megaphone pl-3' /> Reminders */}
                <i className='pi pi-eye pl-3' /> Reminders
              </div>
            </div>
          );
        }}
        // cellRender={cellRender}
        fullCellRender={cellRender}
      />
      <Dialog header={dateSelect} maximizable visible={visible} modal={true} style={{ width: '60vw' }} onHide={() => setVisible(false)}>
        <SelectedDayTcms date={dateSelect} active={active} events={myEvents} relad={relad} />
      </Dialog>
    </div>
  );
};
type Props = {
  active?: Active;
  relad(): void;
};

export { TcmsDashboard };
