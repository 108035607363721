import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { InputField } from "../../../modules/commons";
import { UserLogin } from "../../../models";

const LoginForm = ({ setUsername, setPassword }: Props) => {
  const [type, setType] = useState("password");
  const { values } = useFormikContext<UserLogin>();

  useEffect(() => {
    setUsername(values.username);
    setPassword(values.password);
  }, [values, setUsername, setPassword]);

  const handleType = () => {
    type === "text" ? setType("password") : setType("text");
  };
  return (
    <div>

      <InputField
        field="username"
        label="E-mail"
        floatLabel
        inputClassName="mt-2 sticky"
        className='w-full'
      />
      <div className="flex w-full mt-5">
        <InputField
          field="password"
          label="Password"
          floatLabel
          type={type}
          inputClassName="mt-2 sticky"
          className="w-full"
        />
        <FontAwesomeIcon
          icon={type === "text" ? faEyeSlash : faEye}
          onClick={handleType}
          className="-ml-8 z-10 mt-7 cursor-pointer"
        />
      </div>

      {/* <InputField field="securityCode" label="Segundo Factor" mask="9999999" /> */}
    </div>
  );
};

type Props = {
  setUsername: (c: string) => void;
  setPassword: (c: string) => void;
};

export { LoginForm };
