import getFontSizes from "antd/es/theme/themes/shared/genFontSizes";
import { classNames } from "primereact/utils";

const CignaA = () => {
    return (
        <div className="items-start">
            <div>
                <button
                    type="button"
                    className="inline-block rounded-full bg-white px-12 text-sm font-bold uppercase border border-green-600">
                    CLEAR FORM

                </button>
            </div>

            <div className="w-full pb-2">
                <img
                    src="https://api.sunissup.com/static/media/cigna.png"
                    alt="cigna"
                    className="rounded-xl mx-auto"
                    width={200}
                />
            </div>

            <div className="bg-blue-900 text-white pl-2" style={{ fontSize: "28px" }}>
                <p>Medicare Advantage</p>
            </div>
            <div className="bg-blue-900 text-white pl-2" style={{ fontSize: "28px" }}>
                <p>Outpatient Treatment Request</p>
            </div>
            <div className="w-full pt-1">
                <p><b>Fax completed form to: 866-949-4846</b> Fill out completely to avoid delays</p>
            </div>
            <div className="flex w-full pt-1" style={{ fontSize: "22px" }}>
                <p className="pr-2"><b>Request Submission Date:</b></p>
                <div className="border-b border-black"><input className="w-full bg-blue-200" type="text" id="input1" /></div>
            </div>
            <div className="flex">
                <p className="pr-2"><b>Request Type </b>(Check one):</p>
                <input type="checkbox" />
                <p className="pl-2 pr-2"><b>Standard</b></p>
                <input type="checkbox" />
                <p className="pl-2"><b>Expedited (additional information required below):</b></p>
            </div>
            <div className="border-black border">
                <div className="text-red-400 text-center"><p><b>Provider Attestation (Expedited Requests Only)</b></p></div>
                <div className="pl-2"><p><b>Clinical justification for expedited review:</b></p></div>
                <div className="w-full  border-black pl-2 pr-2"><input className="w-full bg-blue-200 border-b border-black" type="text" /></div>
                <div className="pl-2 pr-2 w-full"><p>By signing below, I certify that applying the standard review timeframe for this service request may seriously jeopardize the life or
                    health of the patient or the patient's ability to regain maximum function.</p></div>
                <div className="flex w-full">

                    <div className="flex w-1/2 pl-2">
                        <p className="pr-2"><b>Physician/clinician name:</b></p>
                        <input className="w-3/6 bg-blue-200 border-b border-black" type="text" />
                    </div>

                    <div className="flex w-1/2 pl-2 pr-2">
                        <p className="pr-2"><b>Signature:</b></p>
                        <input className="w-full bg-blue-200 border-b border-black" type="text" />

                    </div>

                </div>

            </div>
            {/* div espacio */}
            <div><p className="w-full pt-2 border-b border-white"></p></div>

            <div className="w-full bg-blue-500 text-white text-center"><p style={{ fontSize: "22px" }}><b>Identifyig Data</b></p></div>

            {/* div espacio */}
            <div><p className="w-full pt-2 border-b border-white"></p></div>

            <div className="flex w-full">
                <div className="flex w-1/2">
                    <div className="flex w-2/3">
                        <p className="pr-2"><b>First: </b></p>
                        <input className="w-full bg-blue-200 border-b border-black" type="text" />
                    </div>
                    <div className="flex w-1/3">
                        <p className="pl-2 pr-2"><b>MI: </b></p>
                        <input className="w-full bg-blue-200 border-b border-black" type="text" />
                    </div>
                </div>
                <div className="flex w-1/2">
                    <p className="pl-2 pr-2"><b>Last: </b></p>
                    <input className="pl-2 w-full bg-blue-200 border-b border-black" type="text" />
                </div>
            </div>

            <div className="flex w-full pt-2">

                <div className="flex w-3/6">
                    <p className="w-2/6"><b>Customer ID:</b></p>
                    <input className="w-full bg-blue-200 border-b border-black" type="text" />
                </div>

                <div className="flex w-2/6">
                    <p className="w-2/6"><b>Date of Birth:</b></p>
                    <input className="w-1/2 bg-blue-200 border-b border-black" type="text" />
                </div>

                <div className="flex w-1/6">

                    <div className="w-1/3"><p><b>Gender:</b></p></div>
                    <div className="flex w-1/3">
                        <input type="checkbox" />
                        <p>Male</p>
                    </div>
                    <div className="flex w-1/3">
                        <input type="checkbox" />
                        <p>Female</p>
                    </div>
                </div>
            </div>
        </div>



    );
}
// type Props = {

// }
export { CignaA };