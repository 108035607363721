import { useEffect, useState } from "react";
// -- PrimeReact Libs
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";

// TODO por mover
import { Active } from "../../../../models";
// -- New struct
import {Events, Event} from "../../../../models"
import {useEventDel} from "../../../../hooks/modules/commons"
import { AddEvent } from "../../../commons/events/add";

const SelectedDayQa = ({ date, active, events, relad }: Props) => {
  const { delEvent } = useEventDel(relad);
  const [visibleEvent, setVisibleEvent] = useState<boolean>(false);
  const [eventActive, setEventActive] = useState<Event | undefined>(undefined);
  const [visibleEventShow, setVisibleEventShow] = useState<boolean>(false);

  // Convertimos itemsNotes en un estado de React

  const [itemsEvents, setItemsEvents] = useState<MenuItem[]>([
    {
      items: [],
    },
  ]);
  // ---
  const itemsReminders: MenuItem[] = [
    {
      // label: 'Reminders',
      items: [
        {
          label: "In a week you must perform Ana Perez SPR",
          className: "bg-red-100 mb-1",
          icon: "pi pi-megaphone",
        },
        {
          label: "Request the signature of the consents from Juan",
          className: "bg-red-100 mb-1 hover:bg-blue-300",
          icon: "pi pi-megaphone",
        },
      ],
    },
  ];

  const handleAddEvent = () => {
    setVisibleEvent(true);
  };
  const onCloseEvent = () => {
    relad();
    setVisibleEvent(false);
  };

  // -------------------
  useEffect(() => {
    const renderedEvents = new Set(); // Usamos un conjunto para evitar duplicados

    const newItemsEvents: MenuItem[] = [
      // Inicializamos newItemsEvents como un array vacío
      {
        items: [],
      },
    ];

    events?.all_event?.forEach((item) => {
      if (date === item.date) {
        if (!renderedEvents.has(item.ID)) {
          renderedEvents.add(item.ID); // Agregamos el cliente al conjunto
          // Comprobamos si el cliente ya ha sido añadido a itemsNotes
          const clientAlreadyAdded = newItemsEvents.some(
            (note) =>
              note.label === item.title
          );
          if (!clientAlreadyAdded) {
            // Agregamos el cliente a itemsNotes
            newItemsEvents.push({
              label: item.title,
              icon: "pi pi-flag",
              //   className:'bg-orange-400',
              command: () => {
                setEventActive(item);
                // setActiveClient(client);
                setVisibleEventShow(true);
              },
            });
          }
        }
      }
    });

    // Actualizamos el estado de itemsNotes con la nueva lista
    setItemsEvents(newItemsEvents);
  }, [relad]);

  return (
    <div className="card flex justify-content-center">
      <div className="flex w-full">


        <div className="w-1/2 m-0 border-gray-200 pr-4 border-r-2 mr-4">
          <div className="w-full flex ">
            <div className="w-2/3">
              <h1 className="flex mb-2 text-lg font-bold">Events</h1></div>
            <div className="w-1/3 grid text-right p-0">
              {date >= (active?.activeUser?.WeekActive?.start ?? "") &&
                <Button label="ADD" className="m-0" icon="pi pi-plus" size="small" onClick={handleAddEvent} />
              }
            </div>
          </div>
          <Menu
            model={itemsEvents}
            pt={{
              root: {
                className: classNames("w-full"),
              },
              menuitem: {
                className: classNames("bg-blue-100 mb-1"),
              },
            }}
          />
          <Dialog
            header={`Add Event on (${date})`}
            maximizable
            visible={visibleEvent}
            style={{ width: "50vw" }}
            onHide={() => setVisibleEvent(false)}
          >
            <AddEvent
              active={active}
              date={date}
              relad={relad}
              setVisible={onCloseEvent}
            />
          </Dialog>
          <Dialog
            header={eventActive?.title}
            // maximizable
            visible={visibleEventShow}
            style={{ width: "40vw" }}
            closeOnEscape
            onHide={() => setVisibleEventShow(false)}
          >
            <div className="card flex justify-content-center">
              <b>Description:</b>
              <div dangerouslySetInnerHTML={{ __html: eventActive?.description ?? "" }} />

              {/* <Chip className="pl-0 pr-3 mt-5 " template={content} /> */}
              <div className="w-full text-right ">
                <Button
                  icon="pi pi-trash"
                  rounded
                  raised
                  aria-label="Dalete"
                  className="pl-3 bg-red-400 hover:bg-secondary"
                  onClick={() => {
                    delEvent({ id: eventActive?.ID ?? 0 })
                    setVisibleEventShow(false);
                  }}
                />
              </div>
            </div>
          </Dialog>
        </div>

        <div className="w-1/2 m-0">
          <h2 className="mb-2 text-lg font-bold">Reminders</h2>
          <Menu
            model={itemsReminders}
            pt={{
              root: {
                className: classNames("w-full"),
              },
              menuitem: {
                className: classNames("rounded"),
              },
            }}
          />
        </div>

      </div>
    </div>
  );
};
type Props = {
  date: string;
  active: Active | undefined;
  events: Events | undefined;
  relad(): void;
}
export { SelectedDayQa };
