import { classNames } from "primereact/utils";

const Objetives = () => {
    return (
        <div style={{ fontSize: "14px" }}>
            <div className="w-full text-justify mb-5">
                The following objectives has been developed According with TCM Medicaid Handbook Section 2-15 and 2-17 to comply with Service Plan/Service Plan
                Review/Addendums documentation polices. SUNISS UP determined that each individualized Long-Term Goal developed on this Service Plan
                and corresponding Addendums will have implicit each of those Objectives described with corresponding tasks according the nature of
                each Need and Goal. The Objectives showed wide range of activities which could be developed by TCM with the active participation of the
                client during follow up process. Objectives described will have the same Start and Target Dates of each Goal from this Service Plan/Addendum.
            </div>
            {/* Objective 1 */}
            <div className="w-full border border-primary ">
                <div className='w-full flex'>
                    <div className='w-1/5 border-r border-primary pl-5' style={{ backgroundColor: "#3f5b9e", color: "#ffffff" }}>
                        <b style={{ fontSize: "18px" }}>Objective 1</b>
                        <br />
                        <b>Task</b> (who will do what)
                    </div>
                    <div className='w-4/5 pl-5'>
                        <b>Advocating for the acquisition of services and resources necessary to implement the service plan by
                            representing or defending recipients through direct intervention. (TCM Handbook S-2-17)
                        </b>
                    </div>
                </div>
                <div className='w-full border-t border-primary p-2'>
                    <b style={{ fontSize: "16px" }}>
                        TCM will link the client with any services/programs advocated previously by TCM by target date.
                    </b>
                </div>
                <div className='w-full border-t border-primary p-2'>
                    -TCM will update and gather information about specific service/program opened at community and the interventions will be
                    developed by phone calls, program personal visits and/or using online resources and tools.<br />
                    -Client/Legal Guardian will be educated regarding documentation and follow up during services last.<br />
                    -TCM will be able to advocate related services to accomplish the Objective and Goal; ex: DCF/SSA, Police Services, Bank
                    Statements, OTC, pharmacy services, medication management, transportation, lab test, X-Rays, Legal Services, Social Services,
                    and any other service required during follow up.
                </div>
            </div>
            {/* Objective 2 */}
            <div className="w-full border border-t-0 border-primary">
                <div className='w-full flex'>
                    <div className='w-1/5 border-r border-primary pl-5' style={{ backgroundColor: "#3f5b9e", color: "#ffffff" }}>
                        <b style={{ fontSize: "18px" }}>Objective 2</b>
                        <br />
                        <b>Task</b> (who will do what)
                    </div>
                    <div className='w-4/5 pl-5'>
                        <b>
                            Linking and facilitating the recipient with appropriate services and resources identified in the
                            service plan through referrals to reach desired goals. (TCM Handbook S-2-17)
                        </b>
                    </div>
                </div>
                <div className='w-full border-t border-primary p-2'>
                    <b style={{ fontSize: "16px" }}>
                        TCM will link the client with any services/programs advocated previously by TCM by target date.
                    </b>
                </div>
                <div className='w-full border-t border-primary p-2'>
                    - TCM will complete linking process by contacting client/service/programs as required by phone calls, personal visits and
                    using Internet resources.<br />
                    - TCM will be able to link the client with any related services/program advocated previously to accomplish the Objective and
                    Goal; ex: DCF/SSA, Police Services, Bank Statements, OTC, pharmacy services, medication management, transportation, lab
                    test, X-Rays, Legal Services, Social Services, and any other service required during follow up.
                </div>
            </div>
            {/* Objective 3 */}
            <div className="w-full border border-t-0 border-primary">
                <div className='w-full flex'>
                    <div className='w-1/5 border-r border-primary pl-5' style={{ backgroundColor: "#3f5b9e", color: "#ffffff" }}>
                        <b style={{ fontSize: "18px" }}>Objective 3</b>
                        <br />
                        <b>Task</b> (who will do what)
                    </div>
                    <div className='w-4/5 pl-5'>
                        <b>
                            Coordinating the delivery of services as specified in the service plan with the help of the recipient,
                            the recipient’s family, and the recipient’s natural support system. (TCM Handbook S-2-17)
                        </b>
                    </div>
                </div>
                <div className='w-full border-t border-primary p-2'>
                    <b style={{ fontSize: "16px" }}>
                        TCM will coordinate all required services/programs during entire follow up by target date.
                    </b>
                </div>
                <div className='w-full border-t border-primary p-2'>
                    - TCM will be able to coordinate activities on behalf of the client according to the specific nature of each goal through and
                    not limited to coordination of appointments/interviews/applications/recertification/hearings/meetings/follow ups/discharge, etc.,
                    based on contact by phone calls/personal visit and using internet tool developed in conjunction with the client/Legal
                    Gurdian and specific service/program.
                </div>
            </div>
            {/* Objective 4 */}
            <div className="w-full border border-t-0 border-primary">
                <div className='w-full flex'>
                    <div className='w-1/5 border-r border-primary pl-5' style={{ backgroundColor: "#3f5b9e", color: "#ffffff" }}>
                        <b style={{ fontSize: "18px" }}>Objective 4</b>
                        <br />
                        <b>Task</b> (who will do what)
                    </div>
                    <div className='w-4/5 pl-5'>
                        <b>
                            Monitoring service delivery to evaluate the recipient’s progress. (TCM Handbook S-2-17)
                        </b>
                    </div>
                </div>
                <div className='w-full border-t border-primary p-2'>
                    <b style={{ fontSize: "16px" }}>
                        TCM will monitor progress, compliance, and ability of the client to be independent to manage each
                        need by self by target date.
                    </b>
                </div>
                <div className='w-full border-t border-primary p-2'>
                    - TCM will be able to develop monitoring of the progress of the client by contacting Client/Programs as required by phone calls,
                    personal visit or using web portals if necessary.<br />
                    - TCM will complete interviews, staffing, meetings, etc., with Client/Legal Guardian/Programs to assess effectiveness of the
                    services received by client and to evaluate compliance of client with the follow up and ability to be functional and
                    independent using community resources.<br />
                    - TCM will count pills with Client/Legal Guardian during home visits if necessary to monitor compliance with medication
                    management
                </div>
            </div>
            {/* Objective 5 */}
            <div className="w-full border border-t-0 border-primary">
                <div className='w-full flex'>
                    <div className='w-1/5 border-r border-primary pl-5' style={{ backgroundColor: "#3f5b9e", color: "#ffffff" }}>
                        <b style={{ fontSize: "18px" }}>Objective 5</b>
                        <br />
                        <b>Task</b> (who will do what)
                    </div>
                    <div className='w-4/5 pl-5'>
                        <b>
                            Documenting mental health targeted case management activities in accordance with the
                            documentation requirements in this chapter. (TCM Handbook S-2-17)
                        </b>
                    </div>
                </div>
                <div className='w-full border-t border-primary p-2'>
                    <b style={{ fontSize: "16px" }}>
                        TCM will complete and save on client’s record all required documentation during follow up by
                        target date.
                    </b>
                </div>
                <div className='w-full border-t border-primary p-2'>
                    - TCM will generate required documentation to comply with follow up and Medicaid Rules by target date.<br />
                    - TCM will be able to develop Assessment/Service Plans/Addendums and Daily Progress Notes to detail the nature of
                    services provided. On Daily Progress Notes TCM will focus on genuine intervention on behalf of the client and related with the
                    goals described on this Service Plan.
                </div>
            </div>
        </div>
    );
}
// type Props = {

// }
export { Objetives };