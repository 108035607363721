import React, { useState } from "react";
import { useCreatePdf } from "../../profile/hooks";
import { ServiceCM } from "../../../models";
import { Button } from "primereact/button";
import { CustomCheckbox } from "../CustomCheckbox";
const AetnaBHA = ({ scm }: Props) => {
  const { createPDF } = useCreatePdf();
  const [check, setChecked] = useState<boolean>(true);
  const handleDownloadPDF1 = async () => {
    const element = document.getElementById("content-to-pdf");
    if (element) {
      const divContent = element.innerHTML;
      createPDF({ htmlDiv: divContent });
    }
  };
  return (
    <div style={{ height: "80vh", overflow: "auto" }}>
      <Button
        label="Download"
        icon="pi pi-file-pdf"
        onClick={handleDownloadPDF1}
        className="p-button-text"
      />
      <div id="content-to-pdf" className="p-5">
        <div className="w-full text-center">
          <b style={{ fontSize: "20px" }}>
            BEHAVIORAL HEALTH PRIOR AUTHORIZATION REQUEST
          </b>
        </div>
        {/* ----------- */}
        <div className="w-full flex mt-5">
          <div className="w-2/5">
            <b>
              Aetna Better Health of Florida
              <br />
              261 N. University Drive
              <br />
              Plantation, FL 33324
              <br />
              Telephone Number:
              <br />
              Fax Number (Medicaid): 833-365-2474
              <br />
              Fax Number (FHK): 833-365-2493
              <br />
              TTY: 711
            </b>
          </div>
          <div className="w-2/5 text-right flex items-end justify-end">
            <b>Date of Request:</b>
          </div>
          <div className="w-1/5 text-center items-center">
            <img
              src="https://api.sunissup.com/static/media/aetna.png"
              alt="aetna"
              className="rounded-xl"
            />
          </div>
        </div>
        {/* ----------- */}
        <div className="w-full flex">
          <div className="w-auto mr-5">SERVICE TYPE:</div>
          <div className="w-4/5 flex bg-slate-500">
            <CustomCheckbox value={check} onChange={setChecked} />
            <div className="mr-20">PSYCHOLOGICAL / NEUROPSYCHOLOGICAL </div>
            <CustomCheckbox value={check} onChange={setChecked} />
            <div className="text-right items-end">
              APPLIED BEHAVIOR ANALYSIS (ABA){" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
type Props = {
  scm: ServiceCM | undefined;
};
export { AetnaBHA };
