import React from 'react';
import { Button } from 'primereact/button';
import { useCreatePdf } from "../profile/hooks";

interface DownloadPDFButtonProps {
  elementId: string;
  label: string;
  icon: string;
  className?: string;
}

const DownloadPDFButton: React.FC<DownloadPDFButtonProps> = ({ elementId, label, icon, className }) => {
  const { createPDF } = useCreatePdf();

  const handleDownloadPDF = async () => {
    const element = document.getElementById(elementId);
    if (element) {
      const divContent = element.innerHTML;
      createPDF({ htmlDiv: divContent });
    }
  };

  return (
    <Button
      label={label}
      icon={icon}
      onClick={handleDownloadPDF}
      className={className}
    />
  );
};

export {DownloadPDFButton};
