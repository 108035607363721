import { useState, useEffect } from 'react';
// -- Primereact Libs
import { classNames } from 'primereact/utils';
import { SpeedDial } from 'primereact/speeddial';
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import { Nullable } from "primereact/ts-helpers";
// -- ANTD Libs
import { Calendar as AntdCalendar, CalendarProps } from 'antd';
// -- Other Libs
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import dayLocaleData from 'dayjs/plugin/localeData';
// ------
import { Active } from "../../../models";
// TODO New struct
import { useEvents } from "../../../hooks/modules/commons";
import { useTcmSupervisios, useTCMClientsActive, useTcmNotes } from "../../../hooks/modules/tcm";
// -- Component
import { DiaryTcm } from "./components";

dayjs.extend(dayLocaleData);


function formatDate(date: Date) {
  let day = ('0' + date.getDate()).slice(-2);
  let month = ('0' + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear();

  return month + '/' + day + '/' + year;
}

const TCMDashboard = ({ active, relad }: Props) => {
  // TODO: Aqui ahi que tratar de filtrar las notas, y los eventos solo del mes que se este mostrando, 
  // para que no se ponga lento, ahora backend esta enviando todo.
  const { tcmNotes, isLoading, reloadTcmNotes } = useTcmNotes();
  const { myClientsActive, reloadMyClientsActive } = useTCMClientsActive();
  const { tcmSupervisons, reloadTcmSupervisons } = useTcmSupervisios({ id: active?.activeUser?.User?.ID.toString() ?? "0" });
  const { myEvents, reloadMyEvents } = useEvents();
  const [visible, setVisible] = useState<boolean>(false);
  const [dateSelect, setDateSelect] = useState<string>("");


  // -----------
  // -- Siempre se muestra el mes donde este la semana activa
  const [dateRender, setDateRender] = useState<Dayjs>(active?.activeUser?.WeekActive?.end ? dayjs(active.activeUser.WeekActive.end) : dayjs());
  const [yearRender, setYearRender] = useState<Nullable<Date>>(active?.activeUser?.WeekActive?.end ? dayjs(active.activeUser.WeekActive.end).toDate() : dayjs().toDate());
  const [monthRender, setMonthRender] = useState<Nullable<Date>>(active?.activeUser?.WeekActive?.end ? dayjs(active.activeUser.WeekActive.end).toDate() : dayjs().toDate());
  // Función para restar un mes
  const subtractMonth = () => {
    setDateRender(dateRender.subtract(1, 'month'));
    setYearRender(dateRender.subtract(1, 'month').toDate());
    setMonthRender(dateRender.subtract(1, 'month').toDate());
  };
  // Función para sumar un mes
  const addMonth = () => {
    setDateRender(dateRender.add(1, 'month'));
    setYearRender(dateRender.add(1, 'month').toDate());
    setMonthRender(dateRender.add(1, 'month').toDate());
  };
  // -----------

  const dateCellRender = (value: Dayjs) => {
    const current = dayjs().format('MM/DD/YYYY');

    const dates = value.format("MM/DD/YYYY");
    const renderedClients = new Set(); // Usamos un conjunto para evitar duplicados
    let numNotes = 0;
    let numEvents = 0;
    let numReminders = 0;
    const renderedNotes = tcmNotes?.all_notes?.map((item) => {
      if (dates === item.date) {
        return myClientsActive?.clients?.map((client) => {

          if (item.scm.toString() === client.id.toString()) {
            if (!renderedClients.has(client.id)) {
              renderedClients.add(client.id); // Agregamos el cliente al conjunto
              numNotes = numNotes + 1;
              return (

                <li key={client.id} className='flex'>
                  <Tooltip target={`.custom-target-icon-${client.id}`} />

                  <Badge value="N"
                    severity={'warning'}
                    className={classNames(
                      `custom-target-icon-${client.id}`,
                    )}
                    data-pr-tooltip={`Note ${item.timeIn} - ${item.timeOut}`}
                    data-pr-position="top"
                  />

                  {/* <i
                    className={classNames(
                      `custom-target-icon-${client.id}`,
                      "pi pi-file-edit flex text-yellow-500"
                    )}
                    data-pr-tooltip={`${item.timeIn} - ${item.timeOut}`}
                    data-pr-position="top"
                  /> */}
                  {dates < (active?.activeUser?.WeekActive?.start ?? "") ? (
                    <s className='pl-2 text-gray-500'>{client.first_name} {client.last_name}</s>
                  ) : dates > (active?.activeUser?.WeekActive?.end ?? "") ? (
                    <i className='pl-2'>{client.first_name} {client.last_name}</i>
                  ) : (
                    <span className='pl-2'>{client.first_name} {client.last_name}</span>
                  )}
                </li>
              );
            }
          }
          return null; // Omitir elementos duplicados o sin nota
        });
      }
      return null; // Omitir elementos si no se cumple la condición
    });

    const renderedEvents = myEvents?.all_event.map((item) => {
      if (dates === item.date) {
        numEvents++;
        return (

          <li key={item.ID} className='flex'>
            {/* <i
              className={classNames(
                "pi pi-flag flex text-blue-500"
              )}
            /> */}
            <Tooltip target={`.custom-target-event-${item.ID}`} />
            <Badge value="E"
              severity={'info'}
              className={classNames(
                `custom-target-event-${item.ID}`,
              )}
              data-pr-tooltip={"Event"}
              data-pr-position="top"
            />
            {dates < (active?.activeUser?.WeekActive?.start ?? "") ? (
              <s className='pl-2'>{item.title}</s>
            ) : dates > (active?.activeUser?.WeekActive?.end ?? "") ? (
              <i className='pl-2'>{item.title}</i>
            ) : (
              <span className='pl-2'>{item.title}</span>
            )}
          </li>
        );
      }
      return null;
    });

    const renderedReminders = tcmSupervisons?.supervisions?.map((item) => {
      let datesReminders = new Date(item.date);

      if (dates === formatDate(datesReminders)) {
        numReminders++;
        let palabras = item.title.split(" ");
        let primerasDosPalabras = palabras.slice(0, 2).join(" ");
        return (
          <>
            <Tooltip target={`.custom-target-reminders-${item.id}`} />
            <li key={item.id}
              className={`custom-target-reminders-${item.id} flex`}
              data-pr-tooltip={`${item.title}`}
              data-pr-position="top"
            >

              {/* <i
                className={classNames(
                  "pi pi-file-check flex text-purple-500"
                )}

              /> */}
              <Tooltip target={`.custom-target-reminders-${item.id}`} />

              <Badge value="S"
                severity={'success'}
                className={classNames(
                  `custom-target-reminders-${item.id}`,
                )}
                data-pr-tooltip={`Supervision`}
                data-pr-position="top"
              />
              {item.completed ? (
                <s className='pl-2 text-gray-400'>{primerasDosPalabras}...</s>
              ) : dates > (active?.activeUser?.WeekActive?.end ?? "") ? (
                <i className='pl-2'>{primerasDosPalabras}...</i>
              ) : (
                <span className='pl-2'>{primerasDosPalabras}...</span>
              )}
              {/* {dates < (active?.activeUser?.WeekActive?.start ?? "") ? (
                <s className='pl-2'>{primerasDosPalabras}...</s>
              ) : dates > (active?.activeUser?.WeekActive?.end ?? "") ? (
                <i className='pl-2'>{primerasDosPalabras}...</i>
              ) : (
                <span className='pl-2'>{primerasDosPalabras}...</span>
              )} */}
            </li>
          </>
        );
      }
      return null;
    });

    const combineAndAlternate = (arr1: JSX.Element[], arr2: JSX.Element[], arr3: JSX.Element[]) => {
      const result: JSX.Element[] = [];
      for (let i = 0; i < Math.max(arr1.length, arr2.length, arr3.length); i++) {
        if (i < arr1.length) {
          result.push(arr1[i]);
        }
        if (i < arr2.length) {
          result.push(arr2[i]);
        }
        if (i < arr3.length) {
          result.push(arr3[i]);
        }
      }
      return result;
    };

    const safeRenderedNotes = (renderedNotes || []).flat().filter((item): item is JSX.Element => item !== null && item !== undefined);
    const safeRenderedEvents = (renderedEvents || []).flat().filter((item): item is JSX.Element => item !== null && item !== undefined);
    const safeRenderedReminders = (renderedReminders || []).flat().filter((item): item is JSX.Element => item !== null && item !== undefined);

    const combinedEvents = combineAndAlternate(safeRenderedNotes, safeRenderedEvents, safeRenderedReminders);

    return (
      <div
        className={classNames(
          'h-36 p-1 pt-0 border-t hover:bg-gray-100',
          'p-ripple',
        )}
        onClick={() => { onSelect(value) }}
      >
        {/* TODO: Modificar estructura de calencario */}
        <div className={classNames(
          'w-full shadow-lg',
          // dates === dateSelect ? 'bg-green-100' : numNotes > 0 ? 'bg-yellow-100' : numEvents > 0 && 'bg-yellow-100',
          dates === dateSelect ? 'bg-green-100' : (
            ((dates >= (active?.activeUser?.WeekActive?.start ?? "")) && (dates <= (active?.activeUser?.WeekActive?.end ?? ""))) ? 'bg-blue-100' :
              numNotes > 0 ? 'bg-yellow-100' : numEvents > 0 ? 'bg-yellow-100' : numReminders > 0 && 'bg-yellow-100'
          ),
        )}>
          <div className={classNames(
            'w-full flex shadow-sm text-center pt-2'
          )}>
            <div className='w-1/2 text-right flex'>
              {/* {numNotes > 0 &&
                <div className='pl-2'>
                  {numNotes.toString()}
                  <i
                    className={classNames(
                      'pi pi-file-edit'
                    )}
                  />
                </div>
              } */}
              {/* {numEvents > 0 &&
                <div className='pl-2 p-overlay-badge'>
                  Events
                  <Badge
                    value={numEvents.toString()}
                    severity={'info'}
                  />
                </div>
              } */}

            </div>
            <div className='text-center'>
              {(numEvents || numNotes) > 0 &&
                <i className='pi pi-thumbtack' style={{ position: 'relative', top: "-5px", transform: "rotate(30deg)" }} />
              }
            </div>
            <div className='w-1/2 text-right justify-content-center'>
              {numReminders > 0 && current <= dates && (new Date(dates).getMonth() === new Date(current).getMonth()) && (
                <>
                  <Tooltip target={`.custom-target-eye-${dates.replace(/\//g, '')}`} />
                  <i
                    className={classNames(
                      'custom-target-eye-' + dates.replace(/\//g, ''),
                      'pi pi-eye text-red-500 pr-1',
                      'animate-blink'
                    )}
                    data-pr-tooltip="Reminders"
                    data-pr-position="left"
                  />
                </>

              )}
              <Badge value={dates.split("/", 2)[1]} className={classNames(
                dates === current ? 'bg-primary' : dates === dateSelect ? 'bg-gray-300 text-primary' : dates.split("/", 2)[0] !== current.split("/", 2)[0] ? 'bg-transparent text-gray-300' : 'bg-transparent text-primary'
              )}></Badge>
            </div>
          </div>
          <div className={classNames(
            'w-full text-left pl-1 overflow-y-auto h-24',
          )}>
            {isLoading ? (
              <>
                <Skeleton className="mb-2"></Skeleton>
                <Skeleton width="10rem" className="mb-2"></Skeleton>
                <Skeleton width="5rem" className="mb-2"></Skeleton>
              </>
            ) : (
              <ul>
                {combinedEvents}
              </ul>
            )}

          </div>


        </div>
        <Ripple
          pt={{
            root: { style: { background: 'rgba(255, 193, 6, 0.3)' } }
          }}
        />
      </div >
    )
  };

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    return info.originNode;
  };

  const onSelect = (value: Dayjs) => {
    const dates = value.format("MM/DD/YYYY");
    // -- Si la fecha pertenece al mes que se esta renderizando se muestra lo que tienen el dia
    const isSameMonth = value.isSame(dayjs(dateRender), 'month');
    if (isSameMonth) {
      setVisible(true);
    }
    // -----
    setDateSelect(dates);
    setDateRender(dayjs(dates));
    setYearRender(dayjs(dates).toDate());
    setMonthRender(dayjs(dates).toDate());

  };


  useEffect(() => {
    // -- Recargar los datos
    if (active?.activeUser?.User?.roll === "TCM") {
      reloadTcmNotes();
      reloadMyClientsActive();
      reloadTcmSupervisons();
    }
    reloadMyEvents();

    if (typeof document !== 'undefined') {
      // Ahora puedes utilizar el objeto 'document' de manera segura
      let elements = Array.from(document.querySelectorAll('.transition-opacity'));

      // Mezcla el array de elementos
      elements = elements.sort(() => Math.random() - 0.1);

      let i = 0;

      const interval = setInterval(() => {
        if (i >= elements.length) {
          clearInterval(interval);
        } else {
          elements[i].classList.remove('opacity-0');
          elements[i].classList.add('opacity-100');
          i++;
        }
      }, 300);

      return () => clearInterval(interval);
    }
  }, [relad]);

  return (
    // <div className="card w-full lg:card-side ">
    <div className="relative w-full h-screen">
      <div className="sticky top-1/2 transform -translate-y-1/2 right-0 z-50 opacity-20 hover:opacity-100">
        <SpeedDial
          showIcon={<i className="pi pi-angle-right pl-2" style={{ fontSize: '2rem' }}></i>}
          pt={{
            root: {
              className: "right-0 hover:right-5 hover:animate-pulse hover:w-auto"
            },
          }}
          onClick={() => addMonth()}
        />
      </div>
      <div className="sticky top-1/2 transform -translate-y-1/2 left-0 z-50 pl-0 hover:pl-5 opacity-20 hover:opacity-100">
        <SpeedDial
          pt={{
            root: {
              className: "hover:animate-pulse hover:w-auto"
            },
          }}
          showIcon={<i className="pi pi-angle-left" style={{ fontSize: '2rem' }}></i>}
          onClick={() => subtractMonth()}
        />
      </div>

      <AntdCalendar
        className='bg-gray-50'
        mode='month'
        value={dateRender}
        fullscreen={true}
        headerRender={() => {
          return (
            <div className='w-full flex'>
              <div className='w-1/2 pt-5 pl-5 flex'>
                <div className='w-full flex'>
                  <div className='flex justify-center items-center h-full'>
                    <b>SELECT DATE:</b>
                  </div>
                  <div className='w-1/2 flex items-center h-full'>
                    <PrimeCalendar
                      className='w-16 '
                      value={yearRender}
                      variant="filled"
                      onChange={(e) => {
                        setDateRender(dayjs(e.value));
                        setYearRender(e.value);
                        setMonthRender(e.value);
                      }}
                      view="year" dateFormat="yy"
                    />/
                    <PrimeCalendar
                      className='w-28 '
                      value={monthRender}
                      variant="filled"
                      onChange={(e) => {
                        setDateRender(dayjs(e.value));
                        setYearRender(e.value);
                        setMonthRender(e.value);
                      }}
                      view="month" dateFormat="MM"
                    />
                  </div>
                </div>
              </div>
              <div className='w-1/2 text-right pr-5 pt-5 items-center'>
                <b>Legend:</b>
                <Badge value="N" className='ml-3' severity={'warning'} />Notes
                <Badge value="E" className='ml-3' />Events
                <Badge value="S" className='ml-3' severity={'success'} />Supervisions
                <i className='pi pi-eye pl-3 mr-2 text-red-500' /> Reminders
              </div>
            </div>
          );
        }}
        // cellRender={cellRender}
        fullCellRender={cellRender}
      />
      <Dialog header={dateSelect} maximizable visible={visible} modal={true} style={{ width: '60vw' }} onHide={() => setVisible(false)}>
        <DiaryTcm date={dateSelect} active={active} tcmNotes={tcmNotes} myClients={myClientsActive} supervisions={tcmSupervisons} relad={relad} />
      </Dialog>
    </div>
  );
};
type Props = {
  active?: Active;
  relad(): void;
};

export { TCMDashboard };
