import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { post } from "../api";

const useLogin = (
  setError: (error: Error) => void,
  setShowTwoFactor: (status: boolean) => void
) => {
  const navigate = useNavigate();

  const LogIn = async ({ username, password }: Props) => {
    const response = await post(
      "login",
      JSON.stringify({
        email: username,
        password: password,
      })
    );

    const resp = await response.json();
    if (resp.OK === false) {
      throw new Error("Unauthorized", {
        cause: { name: "401", message: "Unauthorized" },
      });
    }
    return resp;
  };
  const { mutate: login, isLoading } = useMutation(LogIn, {
    onError: (error: Error) => {
      setError(error);
    },
    onSuccess: (resp) => {
      // const user = resp as User;
      // if (user?.url) {
      //   setShowTwoFactor(true);
      // } else {
      navigate("/portfolio");
      // }
    },
  });

  return { login, isLogin: isLoading };
};

type Props = {
  username: string;
  password: string;
};

export { useLogin };
