import { useMutation } from "react-query";
import {
  displayNotificationError,
  displayNotificationSuccess,
} from "../../../../utils";

import { put } from "../../../../hooks/api";

const useCoreModifyDateService = (reloadUserActive: () => void) => {
  const newData = async ({ email, service, date }: Props) => {
    const response = await put(
      "verification/serviceModifyDate",
      JSON.stringify({ email: email, service: service, date: date })
    );
    return response.json();
  };

  const { mutate: changeDateService, isLoading: isUpdatingDateService } = useMutation(
    newData,
    {
      onError: (error: Error) => {
        displayNotificationError(error);
      },
      onSuccess: (msg) => {
        displayNotificationSuccess(msg.message);
        reloadUserActive();
      },
    }
  );

  return { changeDateService, isUpdatingDateService };
};
type Props = {
  email: string;
  service: string;
  date: string;
};
export { useCoreModifyDateService };
