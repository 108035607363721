import React, { useEffect, useState } from 'react';
import { useCoreUserInfo } from "../../../../profile/hooks";

const GetInfo = ({ email, date }: Props) => {
    const [content, setContent] = useState<string>("");
    const { userInfo } = useCoreUserInfo({ email });

    // useEffect(() => {
    //     reloadUserInfo();
    // }, [reloadUserInfo]);

    useEffect(() => {
        if (date === 'fullname') {
            setContent(userInfo?.userInfo?.Record?.fullname ?? "");
        } else if (date === 'position_applied') {
            setContent(userInfo?.userInfo?.Record?.position_applied ?? "");
        } else {
            setContent(userInfo?.userInfo?.Record?.fullname ?? "");
        }
    }, [userInfo, date]);

    return <>{content}</>;
};

type Props = {
    email: string;
    date: string;
};

export { GetInfo };