import { createElement } from "react";
import { toast } from "react-toastify";

import {
  NotificationError,
  NotificationSuccess,
  NotificationQuestion,
  NotificationWarning,
} from "./modules/commons";

const displayNotificationQuestion = (message: string) => {
  toast.success(createElement(NotificationQuestion, { message }), {
    autoClose: 2500,
  });
};
const displayNotificationSuccess = (message: string) => {
  toast.success(createElement(NotificationSuccess, { message }), {
    autoClose: 2500,
  });
};

const displayNotificationWarning = (message: string) => {
  toast.warning(createElement(NotificationWarning, { message }), {
    autoClose: 2500,
  });
};

const displayNotificationError = (error: Error) => {
  toast.error(createElement(NotificationError, { error }));
};

const panelColors = ["#EEEEEE", "#9bf4ab", "#70db93", "#28c177", "#027623"];
const weekNames = ["D", "L", "M", "M", "J", "V", "S"];
const monthNames = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "Ma",
  "Jun",
  "Jul",
  "Ag",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];
let today = new Date();
let date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

const until = date;
const strCapitalize = (str: string) =>
  str ? `${str[0].toUpperCase()}${str.substring(1)}` : "";
export {
  panelColors,
  weekNames,
  monthNames,
  until,
  displayNotificationSuccess,
  displayNotificationWarning,
  displayNotificationError,
  displayNotificationQuestion,
  strCapitalize,
};
