import { useEffect, useState, useCallback } from "react";

import { classNames } from "primereact/utils";
import { MenuItem } from "primereact/menuitem";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";


import { DiaryNote } from "./diaryNote";
import { DiaryTCMaddNote } from "./diaryAddNote";
import { EditNotes } from "../../../notes/editNotes";
import { AddEvent } from "../../../../commons/events/add";
import { useEvents } from "../../../../../hooks/modules/commons";

// -- New Struct
import { Active, TcmSupervisons, Event, ClientNotes, Notes, Clients, Client } from "../../../../../models";
import { useTcmBillActive, useNoteDel } from "../../../../../hooks/modules/tcm";
import { useEventDel } from "../../../../../hooks/modules/commons"

function formatDate(date: Date) {
  let day = ('0' + date.getDate()).slice(-2);
  let month = ('0' + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear();

  return month + '/' + day + '/' + year;
}

const DiaryTcm = ({ date, active, tcmNotes, myClients, supervisions, relad }: Props) => {
  const { tcmBillActive } = useTcmBillActive();
  // Event
  const { myEvents, reloadMyEvents } = useEvents();
  const { delNote } = useNoteDel(relad);
  const { delEvent } = useEventDel(relad);
  const [visibleEvent, setVisibleEvent] = useState<boolean>(false);
  const [eventActive, setEventActive] = useState<Event | undefined>(undefined);
  const [visibleEventShow, setVisibleEventShow] = useState<boolean>(false);

  const [visible, setVisible] = useState<boolean>(false);
  const [visibleNotes, setVisibleNotes] = useState<boolean>(false);
  // const [visibleEditNotes, setVisibleEditNotes] = useState<boolean>(false);

  const [noteEdit, setNoteEdit] = useState<boolean>(false);
  const [activeNote, setActiveNote] = useState<Notes | undefined>(undefined);

  const [activeClient, setActiveClient] = useState<Client | undefined>(
    undefined
  );

  // Convertimos itemsNotes en un estado de React
  const [itemsNotes, setItemsNotes] = useState<MenuItem[]>([
    {
      items: [],
    },
  ]);
  const [itemsEvents, setItemsEvents] = useState<MenuItem[]>([
    {
      items: [],
    },
  ]);
  const [itemsReminders, setItemsReminders] = useState<MenuItem[]>([
    {
      items: [],
    },
  ]);
  // ---


  const onClose = () => {
    setVisibleNotes(false);
    setNoteEdit(false)
    setVisible(false);
  };

  const handleAddNote = () => {
    setVisibleNotes(true);
  };
  const handleAddEvent = () => {
    setVisibleEvent(true);
  };
  const onCloseEvent = () => {
    setVisibleEvent(false);
  };

  const Reload = useCallback(() => {
    const renderedClients = new Set(); // Usamos un conjunto para evitar duplicados
    const renderedEvents = new Set(); // Usamos un conjunto para evitar duplicados
    const renderedReminders = new Set(); // Usamos un conjunto para evitar duplicados

    const newItemsNotes: MenuItem[] = [
      // Inicializamos newItemsNotes como un array vacío
      {
        items: [],
      },
    ];
    const newItemsEvents: MenuItem[] = [
      // Inicializamos newItemsEvents como un array vacío
      {
        items: [],
      },
    ];
    const newItemsReminders: MenuItem[] = [
      // Inicializamos newItemsReminders como un array vacío
      {
        items: [],
      },
    ];
    // -----------

    tcmNotes?.all_notes?.forEach((item) => {
      if (date === item.date) {
        myClients?.clients?.forEach((client) => {
          if (item.scm.toString() === client.id.toString()) {
            if (!renderedClients.has(client.id)) {
              renderedClients.add(client.id); // Agregamos el cliente al conjunto
              // Comprobamos si el cliente ya ha sido añadido a itemsNotes
              const clientAlreadyAdded = newItemsNotes.some(
                (note) =>
                  note.label ===
                  `${item.minutes}-minute intervention for ${client.first_name} ${client.last_name}`
              );
              if (!clientAlreadyAdded) {
                // Agregamos el cliente a itemsNotes
                newItemsNotes.push({
                  label: `${item.timeIn} intervention for ${client.first_name} ${client.last_name}`,
                  icon: "pi pi-file-edit",
                  //   className:'bg-orange-400',
                  command: () => {
                    setActiveNote(item);
                    setActiveClient(client);
                    setVisible(true);
                  },
                });
              }
            }
          }
        });
      }
    });

    myEvents?.all_event?.forEach((item) => {
      if (date === item.date) {
        if (!renderedEvents.has(item.ID)) {
          renderedEvents.add(item.ID); // Agregamos el cliente al conjunto
          // Comprobamos si el cliente ya ha sido añadido a itemsNotes
          const clientAlreadyAdded = newItemsEvents.some(
            (note) =>
              note.label === item.title
          );
          if (!clientAlreadyAdded) {
            // Agregamos el cliente a itemsNotes
            newItemsEvents.push({
              label: item.title,
              icon: "pi pi-flag",
              //   className:'bg-orange-400',
              command: () => {
                setEventActive(item);
                // setActiveClient(client);
                setVisibleEventShow(true);
              },
            });
          }
        }
      }
    });

    supervisions?.supervisions?.forEach((item) => {
      let datesReminders = new Date(item.date);
      if (date === formatDate(datesReminders)) {
        if (!renderedReminders.has(item.id)) {
          renderedReminders.add(item.id); // Agregamos el cliente al conjunto
          // Comprobamos si el cliente ya ha sido añadido a itemsNotes
          const clientAlreadyAdded = newItemsReminders.some(
            (note) =>
              note.label === item.title
          );
          if (!clientAlreadyAdded) {
            // Agregamos el cliente a itemsNotes
            newItemsReminders.push({
              label: "Supervision on the topic, " + item.title,
              icon: "pi pi-megaphone",
              className: 'bg-red-200',
              command: () => {
                // setEventActive(item);
                // setActiveClient(client);
                // setVisibleEventShow(true);
              },
            });
          }
        }
      }
    });

    // Actualizamos el estado de itemsNotes con la nueva lista
    setItemsNotes(newItemsNotes);
    setItemsEvents(newItemsEvents);
    setItemsReminders(newItemsReminders);
  }, [date, tcmNotes, myClients, myEvents, supervisions, setActiveNote, setActiveClient, setVisible, setEventActive, setVisibleEventShow, setItemsNotes, setItemsEvents, setItemsReminders]);
  // ----------- Eventes
  const headerEvents = (title) => {
    return (
      <div className="flex w-full place-items-center">
        <div className="flex w-1/3">
          <div className="pl-2 pr-2">
            <b>{title}</b>
          </div>
        </div>
        <div className="w-2/3 text-right">
          <i
            className="pi pi-trash hover:text-red-500  cursor-pointer mr-2"
            onClick={() => {
              delEvent({ id: eventActive?.ID ?? 0 })
              reloadMyEvents();
              setVisibleEventShow(false);
            }}
          />
          {/* <i
            className="pi pi-file-edit hover:text-blue-500  cursor-pointer"
            onClick={() => {
              // setNoteHelp(true);
            }}
          /> */}
        </div>
      </div>
    )
  };
  // ----- Notes
  const headerNote = (title) => {
    const renderIcons = () => (
      <div className="w-2/3 text-right">
        <i
          className="pi pi-trash hover:text-red-500 cursor-pointer mr-2"
          onClick={() => {
            delNote({ id: activeNote?.ID ?? 0 })
            setVisible(false);
          }}
        />
        <i
          className="pi pi-file-edit hover:text-blue-500 cursor-pointer"
          onClick={() => {
            setNoteEdit(true);
          }}
        />
      </div>
    );

    const isWithinActiveWeek = date >= (active?.activeUser?.WeekActive?.start ?? "") && !tcmBillActive?.billing;
    const isAfterActiveWeek = date > (active?.activeUser?.WeekActive?.end ?? "");

    return (
      <div className="flex w-full place-items-center">
        <div className="flex w-1/3">
          <div className="pl-2 pr-2">
            <b>{title}</b>
          </div>
        </div>
        {isWithinActiveWeek || isAfterActiveWeek ? renderIcons() : null}
      </div>
    );
  };

  // -------------------
  useEffect(() => {
    Reload();
  }, [Reload, relad, reloadMyEvents]);

  useEffect(() => {
    Reload();
  }, [Reload, relad]);



  return (
    <div className="card flex justify-content-center">
      <div className="flex w-full">
        <div className="w-1/3 m-0 border-gray-200 pr-4 border-r-2 mr-4">
          <div className="w-full flex">
            <div className="w-2/3">
              <h2 className="flex mb-2 text-lg font-bold">Notes</h2></div>
            <div className="w-1/3 grid text-right">
              {date >= (active?.activeUser?.WeekActive?.start ?? "") &&
                !tcmBillActive?.billing ? (
                <Button label="ADD" className="bg-yellow-300" icon="pi pi-plus" size="small" onClick={handleAddNote} />
              ) : (
                date > (active?.activeUser?.WeekActive?.end ?? "") && <Button label="ADD" className="bg-yellow-300" icon="pi pi-plus" size="small" onClick={handleAddNote} />
              )}
            </div>
          </div>
          <Menu
            model={itemsNotes}
            pt={{
              root: {
                className: classNames("w-full"),
              },
              menuitem: {
                className: classNames("bg-yellow-100 mb-1 rounded"),
              },
            }}
          />

          <Dialog
            header={headerEvents(eventActive?.title)}
            // maximizable
            visible={visibleEventShow}
            style={{ width: "30vw" }}
            closeOnEscape
            onHide={() => setVisibleEventShow(false)}
          >
            <div className="card flex justify-content-center">
              <b>Description:</b>
              <div dangerouslySetInnerHTML={{ __html: eventActive?.description ?? "" }} />
            </div>
          </Dialog>

          <Dialog
            header={headerNote(`Note (${activeNote?.date})`)}
            maximizable
            visible={visible}
            style={{ width: "70vw" }}
            onHide={() => setVisible(false)}
          >
            <DiaryNote
              note={activeNote}
              client={activeClient}
              active={active}
            />
          </Dialog>

          <Dialog
            header={`Add Note (${date})`}
            maximizable
            visible={visibleNotes}
            style={{ width: "80vw" }}
            onHide={() => setVisibleNotes(false)}
          >
            <DiaryTCMaddNote
              active={active}
              date={date}
              clients={myClients?.clients || undefined}
              notes={tcmNotes?.all_notes || undefined}
              relad={relad}
              setVisibleNotes={onClose}
            />
          </Dialog>

          <Dialog
            header={`Edit Note`}
            maximizable
            visible={noteEdit}
            style={{ width: "80vw" }}
            onHide={() => setNoteEdit(false)}
          >
            {activeNote !== undefined ? (
              <EditNotes
                note={activeNote}
                setVisibleNotes={onClose}
                relad={relad}
              />
            ) : (
              "err"
            )}
          </Dialog>
        </div>



        <div className="w-1/3 m-0 border-gray-200 pr-4 border-r-2 mr-4">
          <div className="w-full flex ">
            <div className="w-2/3">
              <h1 className="flex mb-2 text-lg font-bold">Events</h1></div>
            <div className="w-1/3 grid text-right p-0">
              {date >= (active?.activeUser?.WeekActive?.start ?? "") &&
                <Button label="ADD" className="m-0" icon="pi pi-plus" size="small" onClick={handleAddEvent} />
              }
            </div>
          </div>


          <Menu
            model={itemsEvents}
            pt={{
              root: {
                className: classNames("w-full"),
              },
              menuitem: {
                className: classNames("bg-blue-100 mb-1"),
              },
            }}
          />

          <Dialog
            header={`Add Event on (${date})`}
            maximizable
            visible={visibleEvent}
            style={{ width: "30vw" }}
            onHide={() => setVisibleEvent(false)}
          >
            <AddEvent
              active={active}
              date={date}
              relad={relad}
              setVisible={onCloseEvent}
            />
          </Dialog>
        </div>

        <div className="w-1/3 m-0">
          <h2 className="mb-2 text-lg font-bold">Reminders</h2>
          <Menu
            model={itemsReminders}
            pt={{
              root: {
                className: classNames("w-full"),
              },
              menuitem: {
                className: classNames("rounded"),
              },
            }}
          />
        </div>

      </div>
    </div >
  );
};

type Props = {
  date: string;
  active: Active | undefined;
  myClients: Clients | undefined;
  tcmNotes: ClientNotes | undefined;
  supervisions: TcmSupervisons | undefined;
  relad(): void;
}
export { DiaryTcm };
