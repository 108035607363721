export interface FormValueSpInitialAddendums {
    scm: Number;

    developmentDate: string;
    reviewDate: string;
    // section I
    axisCode: string;
    axisCodeDescription: string;
    // -- a
    goal1a1: boolean;
    goal1a1Type: string;
    goal1a1Long: string;
    goal1a1Responsible: string;
    goal1a1StartDate: string;
    goal1a1TargetDate: string;
    goal1a1Met: boolean;
    goal1a1MetDate: string;
    goal1a1OnGoing: boolean;
    goal1a1NotMet: boolean;
    // --
    goal1a2: boolean;
    goal1a2Type: string;
    goal1a2Long: string;
    goal1a2Responsible: string;
    goal1a2StartDate: string;
    goal1a2TargetDate: string;
    goal1a2Met: boolean;
    goal1a2MetDate: string;
    goal1a2OnGoing: boolean;
    goal1a2NotMet: boolean;
    // --
    goal1b: boolean;
    goal1bType: string;
    goal1bLong: string;
    goal1bResponsible: string;
    goal1bStartDate: string;
    goal1bTargetDate: string;
    goal1bMet: boolean;
    goal1bMetDate: string;
    goal1bOnGoing: boolean;
    goal1bNotMet: boolean;
    // --
    goal1c: boolean;
    goal1cType: string;
    goal1cLong: string;
    goal1cResponsible: string;
    goal1cStartDate: string;
    goal1cTargetDate: string;
    goal1cMet: boolean;
    goal1cMetDate: string;
    goal1cOnGoing: boolean;
    goal1cNotMet: boolean;
    // --
    goal1d: boolean;
    goal1dType: string;
    goal1dLong: string;
    goal1dResponsible: string;
    goal1dStartDate: string;
    goal1dTargetDate: string;
    goal1dMet: boolean;
    goal1dMetDate: string;
    goal1dOnGoing: boolean;
    goal1dNotMet: boolean;
    // --
    goal1e: boolean;
    goal1eType: string;
    goal1eLong: string;
    goal1eResponsible: string;
    goal1eStartDate: string;
    goal1eTargetDate: string;
    goal1eMet: boolean;
    goal1eMetDate: string;
    goal1eOnGoing: boolean;
    goal1eNotMet: boolean;
    // --
    goal1f1: boolean;
    goal1f1Type: string;
    goal1f1Long: string;
    goal1f1Responsible: string;
    goal1f1StartDate: string;
    goal1f1TargetDate: string;
    goal1f1Met: boolean;
    goal1f1MetDate: string;
    goal1f1OnGoing: boolean;
    goal1f1NotMet: boolean;
    // --
    goal1f2: boolean;
    goal1f2Type: string;
    goal1f2Text: string;
    goal1f2Long: string;
    goal1f2Responsible: string;
    goal1f2StartDate: string;
    goal1f2TargetDate: string;
    goal1f2Met: boolean;
    goal1f2MetDate: string;
    goal1f2OnGoing: boolean;
    goal1f2NotMet: boolean;
    // -- 2
    goal2a1: boolean;
    goal2a1Type: string;
    goal2a1Long: string;
    goal2a1Responsible: string;
    goal2a1StartDate: string;
    goal2a1TargetDate: string;
    goal2a1Met: boolean;
    goal2a1MetDate: string;
    goal2a1OnGoing: boolean;
    goal2a1NotMet: boolean;
    // --
    goal2b: boolean;
    goal2bType: string;
    goal2bLong: string;
    goal2bResponsible: string;
    goal2bStartDate: string;
    goal2bTargetDate: string;
    goal2bMet: boolean;
    goal2bMetDate: string;
    goal2bOnGoing: boolean;
    goal2bNotMet: boolean;
    // --
    goal2c: boolean;
    goal2cType: string;
    goal2cLong: string;
    goal2cResponsible: string;
    goal2cStartDate: string;
    goal2cTargetDate: string;
    goal2cMet: boolean;
    goal2cMetDate: string;
    goal2cOnGoing: boolean;
    goal2cNotMet: boolean;
    // --
    goal2d1: boolean;
    goal2d1Type: string;
    goal2d1Long: string;
    goal2d1Responsible: string;
    goal2d1StartDate: string;
    goal2d1TargetDate: string;
    goal2d1Met: boolean;
    goal2d1MetDate: string;
    goal2d1OnGoing: boolean;
    goal2d1NotMet: boolean;
    // --
    goal2d2: boolean;
    goal2d2Type: string;
    goal2d2Text: string;
    goal2d2Long: string;
    goal2d2Responsible: string;
    goal2d2StartDate: string;
    goal2d2TargetDate: string;
    goal2d2Met: boolean;
    goal2d2MetDate: string;
    goal2d2OnGoing: boolean;
    goal2d2NotMet: boolean;
    // --
    goal2d3: boolean;
    goal2d3Type: string;
    goal2d3Text: string;
    goal2d3Long: string;
    goal2d3Responsible: string;
    goal2d3StartDate: string;
    goal2d3TargetDate: string;
    goal2d3Met: boolean;
    goal2d3MetDate: string;
    goal2d3OnGoing: boolean;
    goal2d3NotMet: boolean;
    // --
    goal2d4: boolean;
    goal2d4Type: string;
    goal2d4Text: string;
    goal2d4Long: string;
    goal2d4Responsible: string;
    goal2d4StartDate: string;
    goal2d4TargetDate: string;
    goal2d4Met: boolean;
    goal2d4MetDate: string;
    goal2d4OnGoing: boolean;
    goal2d4NotMet: boolean;
    // --
    goal2d5: boolean;
    goal2d5Type: string;
    goal2d5Text: string;
    goal2d5Long: string;
    goal2d5Responsible: string;
    goal2d5StartDate: string;
    goal2d5TargetDate: string;
    goal2d5Met: boolean;
    goal2d5MetDate: string;
    goal2d5OnGoing: boolean;
    goal2d5NotMet: boolean;
    // --
    goal2d6: boolean;
    goal2d6Type: string;
    goal2d6Text: string;
    goal2d6Long: string;
    goal2d6Responsible: string;
    goal2d6StartDate: string;
    goal2d6TargetDate: string;
    goal2d6Met: boolean;
    goal2d6MetDate: string;
    goal2d6OnGoing: boolean;
    goal2d6NotMet: boolean;
    // --
    goal2d7: boolean;
    goal2d7Type: string;
    goal2d7Text: string;
    goal2d7Long: string;
    goal2d7Responsible: string;
    goal2d7StartDate: string;
    goal2d7TargetDate: string;
    goal2d7Met: boolean;
    goal2d7MetDate: string;
    goal2d7OnGoing: boolean;
    goal2d7NotMet: boolean;
    // --
    goal2d8: boolean;
    goal2d8Type: string;
    goal2d8Text: string;
    goal2d8Long: string;
    goal2d8Responsible: string;
    goal2d8StartDate: string;
    goal2d8TargetDate: string;
    goal2d8Met: boolean;
    goal2d8MetDate: string;
    goal2d8OnGoing: boolean;
    goal2d8NotMet: boolean;
    // --
    goal2d9: boolean;
    goal2d9Type: string;
    goal2d9Text: string;
    goal2d9Long: string;
    goal2d9Responsible: string;
    goal2d9StartDate: string;
    goal2d9TargetDate: string;
    goal2d9Met: boolean;
    goal2d9MetDate: string;
    goal2d9OnGoing: boolean;
    goal2d9NotMet: boolean;
    // --
    goal2d10: boolean;
    goal2d10Type: string;
    goal2d10Text: string;
    goal2d10Long: string;
    goal2d10Responsible: string;
    goal2d10StartDate: string;
    goal2d10TargetDate: string;
    goal2d10Met: boolean;
    goal2d10MetDate: string;
    goal2d10OnGoing: boolean;
    goal2d10NotMet: boolean;
    // --
    goal2d11: boolean;
    goal2d11Type: string;
    goal2d11Text: string;
    goal2d11Long: string;
    goal2d11Responsible: string;
    goal2d11StartDate: string;
    goal2d11TargetDate: string;
    goal2d11Met: boolean;
    goal2d11MetDate: string;
    goal2d11OnGoing: boolean;
    goal2d11NotMet: boolean;
    // --
    goal2d12: boolean;
    goal2d12Type: string;
    goal2d12Text: string;
    goal2d12Long: string;
    goal2d12Responsible: string;
    goal2d12StartDate: string;
    goal2d12TargetDate: string;
    goal2d12Met: boolean;
    goal2d12MetDate: string;
    goal2d12OnGoing: boolean;
    goal2d12NotMet: boolean;
    // --
    goal2d13: boolean;
    goal2d13Type: string;
    goal2d13Text: string;
    goal2d13Long: string;
    goal2d13Responsible: string;
    goal2d13StartDate: string;
    goal2d13TargetDate: string;
    goal2d13Met: boolean;
    goal2d13MetDate: string;
    goal2d13OnGoing: boolean;
    goal2d13NotMet: boolean;
    // --
    goal2e1: boolean;
    goal2e1Type: string;
    goal2e1Long: string;
    goal2e1Responsible: string;
    goal2e1StartDate: string;
    goal2e1TargetDate: string;
    goal2e1Met: boolean;
    goal2e1MetDate: string;
    goal2e1OnGoing: boolean;
    goal2e1NotMet: boolean;
    // --
    goal2e2: boolean;
    goal2e2Type: string;
    goal2e2Long: string;
    goal2e2Responsible: string;
    goal2e2StartDate: string;
    goal2e2TargetDate: string;
    goal2e2Met: boolean;
    goal2e2MetDate: string;
    goal2e2OnGoing: boolean;
    goal2e2NotMet: boolean;
    // --
    goal2e3: boolean;
    goal2e3Type: string;
    goal2e3Long: string;
    goal2e3Responsible: string;
    goal2e3StartDate: string;
    goal2e3TargetDate: string;
    goal2e3Met: boolean;
    goal2e3MetDate: string;
    goal2e3OnGoing: boolean;
    goal2e3NotMet: boolean;
    // --
    goal2e4: boolean;
    goal2e4Type: string;
    goal2e4Long: string;
    goal2e4Responsible: string;
    goal2e4StartDate: string;
    goal2e4TargetDate: string;
    goal2e4Met: boolean;
    goal2e4MetDate: string;
    goal2e4OnGoing: boolean;
    goal2e4NotMet: boolean;
    // --
    goal2e5: boolean;
    goal2e5Type: string;
    goal2e5Text: string;
    goal2e5Long: string;
    goal2e5Responsible: string;
    goal2e5StartDate: string;
    goal2e5TargetDate: string;
    goal2e5Met: boolean;
    goal2e5MetDate: string;
    goal2e5OnGoing: boolean;
    goal2e5NotMet: boolean;
    // -- 3
    goal3a: boolean;
    goal3aType: string;
    goal3aLong: string;
    goal3aResponsible: string;
    goal3aStartDate: string;
    goal3aTargetDate: string;
    goal3aMet: boolean;
    goal3aMetDate: string;
    goal3aOnGoing: boolean;
    goal3aNotMet: boolean;
    // --
    goal3a1: boolean;
    goal3a1Type: string;
    goal3a1Long: string;
    goal3a1Responsible: string;
    goal3a1StartDate: string;
    goal3a1TargetDate: string;
    goal3a1Met: boolean;
    goal3a1MetDate: string;
    goal3a1OnGoing: boolean;
    goal3a1NotMet: boolean;
    // --
    goal3a2: boolean;
    goal3a2Type: string;
    goal3a2Long: string;
    goal3a2Responsible: string;
    goal3a2StartDate: string;
    goal3a2TargetDate: string;
    goal3a2Met: boolean;
    goal3a2MetDate: string;
    goal3a2OnGoing: boolean;
    goal3a2NotMet: boolean;
    // --
    goal3a3: boolean;
    goal3a3Type: string;
    goal3a3Long: string;
    goal3a3Responsible: string;
    goal3a3StartDate: string;
    goal3a3TargetDate: string;
    goal3a3Met: boolean;
    goal3a3MetDate: string;
    goal3a3OnGoing: boolean;
    goal3a3NotMet: boolean;
    // --
    goal3a4: boolean;
    goal3a4Type: string;
    goal3a4Long: string;
    goal3a4Responsible: string;
    goal3a4StartDate: string;
    goal3a4TargetDate: string;
    goal3a4Met: boolean;
    goal3a4MetDate: string;
    goal3a4OnGoing: boolean;
    goal3a4NotMet: boolean;
    // --
    goal3a5: boolean;
    goal3a5Type: string;
    goal3a5Long: string;
    goal3a5Responsible: string;
    goal3a5StartDate: string;
    goal3a5TargetDate: string;
    goal3a5Met: boolean;
    goal3a5MetDate: string;
    goal3a5OnGoing: boolean;
    goal3a5NotMet: boolean;
    // --
    goal3b1: boolean;
    goal3b1Type: string;
    goal3b1Long: string;
    goal3b1Responsible: string;
    goal3b1StartDate: string;
    goal3b1TargetDate: string;
    goal3b1Met: boolean;
    goal3b1MetDate: string;
    goal3b1OnGoing: boolean;
    goal3b1NotMet: boolean;
    // --
    goal3b2: boolean;
    goal3b2Type: string;
    goal3b2Long: string;
    goal3b2Responsible: string;
    goal3b2StartDate: string;
    goal3b2TargetDate: string;
    goal3b2Met: boolean;
    goal3b2MetDate: string;
    goal3b2OnGoing: boolean;
    goal3b2NotMet: boolean;
    // --
    goal3b3: boolean;
    goal3b3Type: string;
    goal3b3Long: string;
    goal3b3Responsible: string;
    goal3b3StartDate: string;
    goal3b3TargetDate: string;
    goal3b3Met: boolean;
    goal3b3MetDate: string;
    goal3b3OnGoing: boolean;
    goal3b3NotMet: boolean;
    // --
    goal3b4: boolean;
    goal3b4Type: string;
    goal3b4Long: string;
    goal3b4Responsible: string;
    goal3b4StartDate: string;
    goal3b4TargetDate: string;
    goal3b4Met: boolean;
    goal3b4MetDate: string;
    goal3b4OnGoing: boolean;
    goal3b4NotMet: boolean;
    // --
    goal3b5: boolean;
    goal3b5Type: string;
    goal3b5Long: string;
    goal3b5Responsible: string;
    goal3b5StartDate: string;
    goal3b5TargetDate: string;
    goal3b5Met: boolean;
    goal3b5MetDate: string;
    goal3b5OnGoing: boolean;
    goal3b5NotMet: boolean;
    // --
    goal3b6: boolean;
    goal3b6Type: string;
    goal3b6Long: string;
    goal3b6Responsible: string;
    goal3b6StartDate: string;
    goal3b6TargetDate: string;
    goal3b6Met: boolean;
    goal3b6MetDate: string;
    goal3b6OnGoing: boolean;
    goal3b6NotMet: boolean;
    // --
    goal3b7: boolean;
    goal3b7Type: string;
    goal3b7Long: string;
    goal3b7Responsible: string;
    goal3b7StartDate: string;
    goal3b7TargetDate: string;
    goal3b7Met: boolean;
    goal3b7MetDate: string;
    goal3b7OnGoing: boolean;
    goal3b7NotMet: boolean;
    // --
    goal3b8: boolean;
    goal3b8Type: string;
    goal3b8Long: string;
    goal3b8Responsible: string;
    goal3b8StartDate: string;
    goal3b8TargetDate: string;
    goal3b8Met: boolean;
    goal3b8MetDate: string;
    goal3b8OnGoing: boolean;
    goal3b8NotMet: boolean;
    // --
    goal3c1: boolean;
    goal3c1Type: string;
    goal3c1Long: string;
    goal3c1Responsible: string;
    goal3c1StartDate: string;
    goal3c1TargetDate: string;
    goal3c1Met: boolean;
    goal3c1MetDate: string;
    goal3c1OnGoing: boolean;
    goal3c1NotMet: boolean;
    // --
    goal3c2: boolean;
    goal3c2Type: string;
    goal3c2Long: string;
    goal3c2Responsible: string;
    goal3c2StartDate: string;
    goal3c2TargetDate: string;
    goal3c2Met: boolean;
    goal3c2MetDate: string;
    goal3c2OnGoing: boolean;
    goal3c2NotMet: boolean;
    // --
    goal3c3: boolean;
    goal3c3Type: string;
    goal3c3Long: string;
    goal3c3Responsible: string;
    goal3c3StartDate: string;
    goal3c3TargetDate: string;
    goal3c3Met: boolean;
    goal3c3MetDate: string;
    goal3c3OnGoing: boolean;
    goal3c3NotMet: boolean;
    // --
    goal3c4: boolean;
    goal3c4Type: string;
    goal3c4Long: string;
    goal3c4Responsible: string;
    goal3c4StartDate: string;
    goal3c4TargetDate: string;
    goal3c4Met: boolean;
    goal3c4MetDate: string;
    goal3c4OnGoing: boolean;
    goal3c4NotMet: boolean;
    // --
    goal3c5: boolean;
    goal3c5Type: string;
    goal3c5Long: string;
    goal3c5Responsible: string;
    goal3c5StartDate: string;
    goal3c5TargetDate: string;
    goal3c5Met: boolean;
    goal3c5MetDate: string;
    goal3c5OnGoing: boolean;
    goal3c5NotMet: boolean;
    // --
    goal3c6: boolean;
    goal3c6Type: string;
    goal3c6Long: string;
    goal3c6Responsible: string;
    goal3c6StartDate: string;
    goal3c6TargetDate: string;
    goal3c6Met: boolean;
    goal3c6MetDate: string;
    goal3c6OnGoing: boolean;
    goal3c6NotMet: boolean;
    // --
    goal3d1: boolean;
    goal3d1Type: string;
    goal3d1Long: string;
    goal3d1Responsible: string;
    goal3d1StartDate: string;
    goal3d1TargetDate: string;
    goal3d1Met: boolean;
    goal3d1MetDate: string;
    goal3d1OnGoing: boolean;
    goal3d1NotMet: boolean;
    // --
    goal3d2: boolean;
    goal3d2Type: string;
    goal3d2Long: string;
    goal3d2Responsible: string;
    goal3d2StartDate: string;
    goal3d2TargetDate: string;
    goal3d2Met: boolean;
    goal3d2MetDate: string;
    goal3d2OnGoing: boolean;
    goal3d2NotMet: boolean;
    // --
    goal3d3: boolean;
    goal3d3Type: string;
    goal3d3Long: string;
    goal3d3Responsible: string;
    goal3d3StartDate: string;
    goal3d3TargetDate: string;
    goal3d3Met: boolean;
    goal3d3MetDate: string;
    goal3d3OnGoing: boolean;
    goal3d3NotMet: boolean;
    // --
    goal3d4: boolean;
    goal3d4Type: string;
    goal3d4Long: string;
    goal3d4Responsible: string;
    goal3d4StartDate: string;
    goal3d4TargetDate: string;
    goal3d4Met: boolean;
    goal3d4MetDate: string;
    goal3d4OnGoing: boolean;
    goal3d4NotMet: boolean;
    // --
    goal3d5: boolean;
    goal3d5Type: string;
    goal3d5Long: string;
    goal3d5Responsible: string;
    goal3d5StartDate: string;
    goal3d5TargetDate: string;
    goal3d5Met: boolean;
    goal3d5MetDate: string;
    goal3d5OnGoing: boolean;
    goal3d5NotMet: boolean;
    // --
    goal3d6: boolean;
    goal3d6Type: string;
    goal3d6Long: string;
    goal3d6Responsible: string;
    goal3d6StartDate: string;
    goal3d6TargetDate: string;
    goal3d6Met: boolean;
    goal3d6MetDate: string;
    goal3d6OnGoing: boolean;
    goal3d6NotMet: boolean;
    // --
    goal3d7: boolean;
    goal3d7Type: string;
    goal3d7Text: string;
    goal3d7Long: string;
    goal3d7Responsible: string;
    goal3d7StartDate: string;
    goal3d7TargetDate: string;
    goal3d7Met: boolean;
    goal3d7MetDate: string;
    goal3d7OnGoing: boolean;
    goal3d7NotMet: boolean;
    // --
    goal3e: boolean;
    goal3eType: string;
    goal3eLong: string;
    goal3eResponsible: string;
    goal3eStartDate: string;
    goal3eTargetDate: string;
    goal3eMet: boolean;
    goal3eMetDate: string;
    goal3eOnGoing: boolean;
    goal3eNotMet: boolean;
    // --
    goal3f: boolean;
    goal3fType: string;
    goal3fLong: string;
    goal3fResponsible: string;
    goal3fStartDate: string;
    goal3fTargetDate: string;
    goal3fMet: boolean;
    goal3fMetDate: string;
    goal3fOnGoing: boolean;
    goal3fNotMet: boolean;
    // -- 4
    goal4a: boolean;
    goal4aType: string;
    goal4aLong: string;
    goal4aResponsible: string;
    goal4aStartDate: string;
    goal4aTargetDate: string;
    goal4aMet: boolean;
    goal4aMetDate: string;
    goal4aOnGoing: boolean;
    goal4aNotMet: boolean;
    // --
    goal4b: boolean;
    goal4bType: string;
    goal4bLong: string;
    goal4bResponsible: string;
    goal4bStartDate: string;
    goal4bTargetDate: string;
    goal4bMet: boolean;
    goal4bMetDate: string;
    goal4bOnGoing: boolean;
    goal4bNotMet: boolean;
    // --
    goal4c: boolean;
    goal4cType: string;
    goal4cLong: string;
    goal4cResponsible: string;
    goal4cStartDate: string;
    goal4cTargetDate: string;
    goal4cMet: boolean;
    goal4cMetDate: string;
    goal4cOnGoing: boolean;
    goal4cNotMet: boolean;
    // --
    goal4d: boolean;
    goal4dType: string;
    goal4dLong: string;
    goal4dResponsible: string;
    goal4dStartDate: string;
    goal4dTargetDate: string;
    goal4dMet: boolean;
    goal4dMetDate: string;
    goal4dOnGoing: boolean;
    goal4dNotMet: boolean;
    // --
    goal4e: boolean;
    goal4eType: string;
    goal4eLong: string;
    goal4eResponsible: string;
    goal4eStartDate: string;
    goal4eTargetDate: string;
    goal4eMet: boolean;
    goal4eMetDate: string;
    goal4eOnGoing: boolean;
    goal4eNotMet: boolean;
    // --
    goal4f: boolean;
    goal4fType: string;
    goal4fLong: string;
    goal4fResponsible: string;
    goal4fStartDate: string;
    goal4fTargetDate: string;
    goal4fMet: boolean;
    goal4fMetDate: string;
    goal4fOnGoing: boolean;
    goal4fNotMet: boolean;
    // -- 5
    goal5a: boolean;
    goal5aType: string;
    goal5aLong: string;
    goal5aResponsible: string;
    goal5aStartDate: string;
    goal5aTargetDate: string;
    goal5aMet: boolean;
    goal5aMetDate: string;
    goal5aOnGoing: boolean;
    goal5aNotMet: boolean;
    // --
    goal5b: boolean;
    goal5bType: string;
    goal5bLong: string;
    goal5bResponsible: string;
    goal5bStartDate: string;
    goal5bTargetDate: string;
    goal5bMet: boolean;
    goal5bMetDate: string;
    goal5bOnGoing: boolean;
    goal5bNotMet: boolean;
    // --
    goal5c: boolean;
    goal5cType: string;
    goal5cLong: string;
    goal5cResponsible: string;
    goal5cStartDate: string;
    goal5cTargetDate: string;
    goal5cMet: boolean;
    goal5cMetDate: string;
    goal5cOnGoing: boolean;
    goal5cNotMet: boolean;
    // --
    goal5d: boolean;
    goal5dType: string;
    goal5dLong: string;
    goal5dResponsible: string;
    goal5dStartDate: string;
    goal5dTargetDate: string;
    goal5dMet: boolean;
    goal5dMetDate: string;
    goal5dOnGoing: boolean;
    goal5dNotMet: boolean;
    // --
    goal5e: boolean;
    goal5eType: string;
    goal5eLong: string;
    goal5eResponsible: string;
    goal5eStartDate: string;
    goal5eTargetDate: string;
    goal5eMet: boolean;
    goal5eMetDate: string;
    goal5eOnGoing: boolean;
    goal5eNotMet: boolean;
    // -- 6
    goal6a1: boolean;
    goal6a1Type: string;
    goal6a1Long: string;
    goal6a1Responsible: string;
    goal6a1StartDate: string;
    goal6a1TargetDate: string;
    goal6a1Met: boolean;
    goal6a1MetDate: string;
    goal6a1OnGoing: boolean;
    goal6a1NotMet: boolean;
    // --
    goal6a2: boolean;
    goal6a2Type: string;
    goal6a2Long: string;
    goal6a2Responsible: string;
    goal6a2StartDate: string;
    goal6a2TargetDate: string;
    goal6a2Met: boolean;
    goal6a2MetDate: string;
    goal6a2OnGoing: boolean;
    goal6a2NotMet: boolean;
    // --
    goal6a3: boolean;
    goal6a3Type: string;
    goal6a3Long: string;
    goal6a3Responsible: string;
    goal6a3StartDate: string;
    goal6a3TargetDate: string;
    goal6a3Met: boolean;
    goal6a3MetDate: string;
    goal6a3OnGoing: boolean;
    goal6a3NotMet: boolean;
    // --
    goal6a4: boolean;
    goal6a4Type: string;
    goal6a4Long: string;
    goal6a4Responsible: string;
    goal6a4StartDate: string;
    goal6a4TargetDate: string;
    goal6a4Met: boolean;
    goal6a4MetDate: string;
    goal6a4OnGoing: boolean;
    goal6a4NotMet: boolean;
    // --
    goal6a5: boolean;
    goal6a5Type: string;
    goal6a5Long: string;
    goal6a5Responsible: string;
    goal6a5StartDate: string;
    goal6a5TargetDate: string;
    goal6a5Met: boolean;
    goal6a5MetDate: string;
    goal6a5OnGoing: boolean;
    goal6a5NotMet: boolean;
    // --
    goal6b: boolean;
    goal6bType: string;
    goal6bLong: string;
    goal6bResponsible: string;
    goal6bStartDate: string;
    goal6bTargetDate: string;
    goal6bMet: boolean;
    goal6bMetDate: string;
    goal6bOnGoing: boolean;
    goal6bNotMet: boolean;
    // --
    goal6c: boolean;
    goal6cType: string;
    goal6cLong: string;
    goal6cResponsible: string;
    goal6cStartDate: string;
    goal6cTargetDate: string;
    goal6cMet: boolean;
    goal6cMetDate: string;
    goal6cOnGoing: boolean;
    goal6cNotMet: boolean;
    // --
    goal6d: boolean;
    goal6dType: string;
    goal6dLong: string;
    goal6dResponsible: string;
    goal6dStartDate: string;
    goal6dTargetDate: string;
    goal6dMet: boolean;
    goal6dMetDate: string;
    goal6dOnGoing: boolean;
    goal6dNotMet: boolean;
    // --
    goal6e: boolean;
    goal6eType: string;
    goal6eLong: string;
    goal6eResponsible: string;
    goal6eStartDate: string;
    goal6eTargetDate: string;
    goal6eMet: boolean;
    goal6eMetDate: string;
    goal6eOnGoing: boolean;
    goal6eNotMet: boolean;
    // --
    goal6f: boolean;
    goal6fType: string;
    goal6fLong: string;
    goal6fResponsible: string;
    goal6fStartDate: string;
    goal6fTargetDate: string;
    goal6fMet: boolean;
    goal6fMetDate: string;
    goal6fOnGoing: boolean;
    goal6fNotMet: boolean;
    // -- 7
    goal7a: boolean;
    goal7aType: string;
    goal7aLong: string;
    goal7aResponsible: string;
    goal7aStartDate: string;
    goal7aTargetDate: string;
    goal7aMet: boolean;
    goal7aMetDate: string;
    goal7aOnGoing: boolean;
    goal7aNotMet: boolean;
    // --
    goal7b1: boolean;
    goal7b1Type: string;
    goal7b1Long: string;
    goal7b1Responsible: string;
    goal7b1StartDate: string;
    goal7b1TargetDate: string;
    goal7b1Met: boolean;
    goal7b1MetDate: string;
    goal7b1OnGoing: boolean;
    goal7b1NotMet: boolean;
    // --
    goal7b2: boolean;
    goal7b2Type: string;
    goal7b2Long: string;
    goal7b2Responsible: string;
    goal7b2StartDate: string;
    goal7b2TargetDate: string;
    goal7b2Met: boolean;
    goal7b2MetDate: string;
    goal7b2OnGoing: boolean;
    goal7b2NotMet: boolean;
    // --
    goal7b3: boolean;
    goal7b3Type: string;
    goal7b3Long: string;
    goal7b3Responsible: string;
    goal7b3StartDate: string;
    goal7b3TargetDate: string;
    goal7b3Met: boolean;
    goal7b3MetDate: string;
    goal7b3OnGoing: boolean;
    goal7b3NotMet: boolean;
    // 
    goal7b4: boolean;
    goal7b4Type: string;
    goal7b4Long: string;
    goal7b4Responsible: string;
    goal7b4StartDate: string;
    goal7b4TargetDate: string;
    goal7b4Met: boolean;
    goal7b4MetDate: string;
    goal7b4OnGoing: boolean;
    goal7b4NotMet: boolean;
    // --
    goal7c: boolean;
    goal7cType: string;
    goal7cLong: string;
    goal7cResponsible: string;
    goal7cStartDate: string;
    goal7cTargetDate: string;
    goal7cMet: boolean;
    goal7cMetDate: string;
    goal7cOnGoing: boolean;
    goal7cNotMet: boolean;
    // --
    goal7d: boolean;
    goal7dType: string;
    goal7dLong: string;
    goal7dResponsible: string;
    goal7dStartDate: string;
    goal7dTargetDate: string;
    goal7dMet: boolean;
    goal7dMetDate: string;
    goal7dOnGoing: boolean;
    goal7dNotMet: boolean;
    // --
    goal7e1: boolean;
    goal7e1Type: string;
    goal7e1Long: string;
    goal7e1Responsible: string;
    goal7e1StartDate: string;
    goal7e1TargetDate: string;
    goal7e1Met: boolean;
    goal7e1MetDate: string;
    goal7e1OnGoing: boolean;
    goal7e1NotMet: boolean;
    // --
    goal7e2: boolean;
    goal7e2Type: string;
    goal7e2Long: string;
    goal7e2Responsible: string;
    goal7e2StartDate: string;
    goal7e2TargetDate: string;
    goal7e2Met: boolean;
    goal7e2MetDate: string;
    goal7e2OnGoing: boolean;
    goal7e2NotMet: boolean;
    // --
    goal7e3: boolean;
    goal7e3Type: string;
    goal7e3Long: string;
    goal7e3Responsible: string;
    goal7e3StartDate: string;
    goal7e3TargetDate: string;
    goal7e3Met: boolean;
    goal7e3MetDate: string;
    goal7e3OnGoing: boolean;
    goal7e3NotMet: boolean;
    // --
    goal7e4: boolean;
    goal7e4Type: string;
    goal7e4Long: string;
    goal7e4Responsible: string;
    goal7e4StartDate: string;
    goal7e4TargetDate: string;
    goal7e4Met: boolean;
    goal7e4MetDate: string;
    goal7e4OnGoing: boolean;
    goal7e4NotMet: boolean;
    // --
    goal7e5: boolean;
    goal7e5Type: string;
    goal7e5Long: string;
    goal7e5Responsible: string;
    goal7e5StartDate: string;
    goal7e5TargetDate: string;
    goal7e5Met: boolean;
    goal7e5MetDate: string;
    goal7e5OnGoing: boolean;
    goal7e5NotMet: boolean;
    // --
    goal7e6: boolean;
    goal7e6Type: string;
    goal7e6Long: string;
    goal7e6Responsible: string;
    goal7e6StartDate: string;
    goal7e6TargetDate: string;
    goal7e6Met: boolean;
    goal7e6MetDate: string;
    goal7e6OnGoing: boolean;
    goal7e6NotMet: boolean;
    // --
    goal7e7: boolean;
    goal7e7Type: string;
    goal7e7Text: string;
    goal7e7Long: string;
    goal7e7Responsible: string;
    goal7e7StartDate: string;
    goal7e7TargetDate: string;
    goal7e7Met: boolean;
    goal7e7MetDate: string;
    goal7e7OnGoing: boolean;
    goal7e7NotMet: boolean;
    // --8
    goal8a: boolean;
    goal8aType: string;
    goal8aLong: string;
    goal8aResponsible: string;
    goal8aStartDate: string;
    goal8aTargetDate: string;
    goal8aMet: boolean;
    goal8aMetDate: string;
    goal8aOnGoing: boolean;
    goal8aNotMet: boolean;
    // --
    goal8b: boolean;
    goal8bType: string;
    goal8bLong: string;
    goal8bResponsible: string;
    goal8bStartDate: string;
    goal8bTargetDate: string;
    goal8bMet: boolean;
    goal8bMetDate: string;
    goal8bOnGoing: boolean;
    goal8bNotMet: boolean;
    // --
    goal8c: boolean;
    goal8cType: string;
    goal8cLong: string;
    goal8cResponsible: string;
    goal8cStartDate: string;
    goal8cTargetDate: string;
    goal8cMet: boolean;
    goal8cMetDate: string;
    goal8cOnGoing: boolean;
    goal8cNotMet: boolean;
    // --
    goal8d: boolean;
    goal8dType: string;
    goal8dLong: string;
    goal8dResponsible: string;
    goal8dStartDate: string;
    goal8dTargetDate: string;
    goal8dMet: boolean;
    goal8dMetDate: string;
    goal8dOnGoing: boolean;
    goal8dNotMet: boolean;
    // --
    goal8e: boolean;
    goal8eType: string;
    goal8eText: string;
    goal8eLong: string;
    goal8eResponsible: string;
    goal8eStartDate: string;
    goal8eTargetDate: string;
    goal8eMet: boolean;
    goal8eMetDate: string;
    goal8eOnGoing: boolean;
    goal8eNotMet: boolean;
    // --
    tcm: number;
    categoryTCM: string;
    signatureTcm: string;
    signatureTcmDate: string;
    supervisor: number;
    signatureSupervisor: string;
    signatureSupervisorDate: string;

    qa: number;
    signatureQa: string;
    signatureQaDate: string;
}
// Utils SP
export interface areaNeeds {
    name: string;
    code: string;
}
export const GoalDescription = {
    // -- OPENING
    "": "",
    "OPENING-1": "ADMISION",
    "OPENING-2": "FIRST HOME VISIT",
    "OPENING-3": "GATHERING w/PCP",
    "OPENING-4": "GATHERING w/PSYCHIATRIST",
    "OPENING-5": "GATHERING w/THERAPIST",
    "OPENING-6": "GATHERING w/SPECIALIST",
    "OPENING-7": "GATHERING w/SCHOOL",
    "OPENING-8": "MEDICAID CERTIFICATION",
    "OPENING-9": "ASSESSMENT DEVELOPMENT",
    "OPENING-10": "SERVICE PLAN DEVELOPMENT",
    "OPENING-11": "SERVICE PLAN DISCUSSION",
    // -- SPR-FOLLOWUP
    "SPR-FOLLOWUP-1": "CASE DISCUSION w/SUPERVISOR",
    "SPR-FOLLOWUP-2": "HOME VISIT SPR",
    "SPR-FOLLOWUP-3": "GATHERING w/PCP SPR",
    "SPR-FOLLOWUP-4": "GATHERING w/PSYCHIATRIST SPR",
    "SPR-FOLLOWUP-5": "GATHERING w/THERAPIST SPR",
    "SPR-FOLLOWUP-6": "GATHERING w/SPECIALIST SPR",
    "SPR-FOLLOWUP-7": "GATHERING w/SCHOOL SPR",
    "SPR-FOLLOWUP-8": "MEDICAID CERTIFICATION 6 MONTH",
    "SPR-FOLLOWUP-9": "SERVICES PLAN REVIEW DEVELOPMENT",
    "SPR-FOLLOWUP-10": "SERVICES PLAN REVIEW DISCUSSION",
    // --
    "CLOSING-1": "CLOSING CASE DISCUSSION w/SUPERVISOR",
    "CLOSING-2": "HOME VISIT FOR CLOSING",
    "CLOSING-3": "PCP VISIT FOR CLOSING",
    "CLOSING-4": "PSYCHIATRIST VISIT FOR CLOSING",
    "CLOSING-5": "THERAPISTVISIT FOR CLOSING",
    "CLOSING-6": "SPECIALISTVISIT FOR CLOSING",
    "CLOSING-7": "SCHOOLVISIT FOR CLOSING",
    "CLOSING-8": "SERVICE PLAN DEVELOPMENT FOR CLOSING",
    "CLOSING-9": "SERVICE PLAN DEVELOPMENT DISCUSSION FOR CLOSING",
    "CLOSING-10": "CLOSINGSUMMARY",
    // -- GOALS AUTOGENERATE
    "goal1a1": "1A - Psychotherapy",
    "goal1a2": "1A - ABA",

    "goal1b": "1B - Cognitive Difficulties",
    "goal1c": "1C - Psychiatric Needs",
    "goal1d": "1D - Psycho Sexual",
    "goal1e": "1E - Other",
    "goal2a1": "2A - Medical Needs",
    "goal2b": "2B - Dental Needs",
    "goal2c": "2C - Vision Needs",

    "goal2d1": "2D - Specialist Needs / Cardiologist",
    "goal2d2": "2D - Specialist Needs / Neurologist",
    "goal2d3": "2D - Specialist Needs / Endocrinologist",
    "goal2d4": "2D - Specialist Needs / Podologist",
    "goal2d5": "2D - Specialist Needs / Gastroenterologist",
    "goal2d6": "2D - Specialist Needs / Dermatologist",
    "goal2d7": "2D - Specialist Needs / Allergist|Immunologist",
    "goal2d8": "2D - Specialist Needs / Pulmonologist",
    "goal2d9": "2D - Specialist Needs / Orthopedist",
    "goal2d10": "2D - Specialist Needs / Obstetrician|Gynecologist",
    "goal2d11": "2D - Specialist Needs / Urologist",
    "goal2d12": "2D - Specialist Needs / Nephrologist",
    "goal2d13": "2D - Specialist Needs / Other",

    "goal2e1": "2E - Other / Medical Center",
    "goal2e2": "2E - Other / Sneacker Program",
    "goal2e3": "2E - Other / Golden Ticket",
    "goal2e4": "2E - Other / HHA",
    "goal2e5": "2E - Other / Other",

    "goal3a1": "3A - Housing / ANNUAL RECERTIFICATION",
    "goal3a2": "3A - Housing / SECTION 8",
    "goal3a3": "3A - Housing / LOW INCOME HOUSING",
    "goal3a4": "3A - Housing / AFFORDABLE HOUSING",
    "goal3a5": "3A - Housing / GENERAL HOUSING NEEDS",

    "goal3b1": "3B - Utilities and Expenses / LIHEAP",
    "goal3b2": "3B - Utilities and Expenses / Free Phone",
    "goal3b3": "3B - Utilities and Expenses / Tablet",
    "goal3b4": "3B - Utilities and Expenses / Internet",
    "goal3b5": "3B - Utilities and Expenses / Cable",
    "goal3b6": "3B - Utilities and Expenses / Water",
    "goal3b7": "3B - Utilities and Expenses / Computer",
    "goal3b8": "3B - Utilities and Expenses / FPL",

    "goal3c1": "3C - Economic Assistance / DCF",
    "goal3c2": "3C - Economic Assistance / SSI",
    "goal3c3": "3C - Economic Assistance / SSA",
    // TODO Revizar este goal en la lista porque esta repetido
    "goal3c4": "3C - Economic Assistance / Bank Checking Account",
    "goal3c5": "3C - Economic Assistance / Bank Checking Account",
    "goal3c6": "3C - Economic Assistance / Funeral Plan",

    "goal3d1": "3D - Transportation / Golden Pass",
    "goal3d2": "3D - Transportation / Easy Card",
    "goal3d3": "3D - Transportation / Disable Parking Permit",
    "goal3d4": "3D - Transportation / STS",
    "goal3d5": "3D - Transportation / PAPA",
    "goal3d6": "3D - Transportation / TOPS",
    "goal3d7": "3D - Transportation / Other",

    "goal3e": "3E - Other",
    "goal3f": "3F - Donations Programs",

    "goal4a": "4A - Sports Programs",
    "goal4b": "4B - Afterschool/Daycare",
    "goal4c": "4C - Mentoring",
    "goal4d": "4D - Employment",
    "goal4e": "4E - Summer Camp",
    "goal4f": "4F - Tutoring",

    "goal5a": "5A - DCF Dependency Legal Services",
    "goal5b": "5B - Independent Living Needs",
    "goal5c": "5C - Therapeutic Supervise Family Visitation",
    "goal5d": "5D - Supervised Family Visitation and Contact",
    "goal5e": "5E - Other",

    "goal6a1": "6A - General Education / Hurricane Season",
    "goal6a2": "6A - General Education / Library Card",
    "goal6a3": "6A - General Education / English Classes",
    "goal6a4": "6A - General Education / Adult School",
    "goal6a5": "6A - General Education / Vocational Rehab",

    "goal6b": "6B - Academic Difficulties",
    "goal6c": "6C - School Attendance Difficulties",
    "goal6d": "6D - Peer Difficulties",
    "goal6e": "6E - Vocational Training",
    "goal6f": "6F - Placement Issues",

    "goal7a": "7A - Eviction",

    "goal7b1": "7B - Immigration Issues / Naturalization",
    "goal7b2": "7B - Immigration Issues / Green Card",
    "goal7b3": "7B - Immigration Issues / US Passport",
    "goal7b4": "7B - Immigration Issues / Asylums",

    "goal7c": "7C - Custody",
    "goal7d": "7D - Department of Juvenile Justice",
    "goal7e": "7E - Other",

    "goal8a": "8A - Psychological and Psychiatric Needs",
    "goal8b": "8A - Employment Needs",
    "goal8c": "8A - Vocational Needs",
    "goal8d": "8A - Literacy Needs",
    "goal8e": "8A - Other"
};
