import { MainNavbar } from "./views/components";
import { RoutesContainer } from "./RoutesContainers";
import { Active } from "./models";
const AppLayout = ({ active, relad }: Props) => {
  return (
    <div className="h-screen flex flex-col ">
      {active?.activeUser?.User?.ID && <MainNavbar active={active} relad={relad}/>}
      <div className="w-full h-full">
        <RoutesContainer />
      </div>
    </div>
  );
};
type Props = {
  active?: Active;
  relad(): void;
};
export { AppLayout };
