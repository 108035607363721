import React, { useState, useEffect, useRef } from "react";

import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';

// Certification
import { Certification } from "../../commons/certification/certification";
// Assessment
import { Assessment } from "../../commons/assessment/assessment";
// Sp
import { Sp } from "../../commons/sp/sp";
// AUTH FORM
import { SunshineA } from "../../commons/authform/sunshineA";
import { CignaA } from "../../commons/authform/cignaA";
import { Molina } from "../../commons/authform/molina";
import { AetnaBHA } from "../../commons/authform/aetnaBHA";
import { AetnaDA } from "../../commons/authform/aetnaDA";

import { WellcareA } from "../../commons/authform/wellcareA";
import { Carelon } from "../../commons/authform/carelon";
import { FCC } from "../../commons/authform/fcc";


// -- New Struct
import { Active, ServiceCM } from "../../../models";


export const ListAuthorizations = ({ active, scm, relad, }: Props) => {
  const apiUrlStatic = process.env.REACT_APP_STATIC ?? "no API url";
  const stepperRef = useRef<null | any>(null);
  const [insuance, setInsuance] = useState<string>("sunshine");
  const [fileSelect, setFileSelect] = useState<string>("form");
  const items: MenuItem[] = [
    {
      label: 'Documents',
      items: [
        {
          label: 'Auth Request',
          icon: 'pi pi-file',
          command: () => {
            setFileSelect("form");
          }
        },
        {
          label: 'Certification',
          icon: 'pi pi-file',
          command: () => {
            setFileSelect("certification");
          }
        },
        {
          label: 'Assessment',
          icon: 'pi pi-file',
          command: () => {
            setFileSelect("assessment");
          }
        },
        {
          label: 'Service Plan',
          icon: 'pi pi-file',
          command: () => {
            setFileSelect("sp");
          }
        },
        {
          label: 'Evaluation',
          icon: 'pi pi-file'
        }
      ]
    }
  ];

  const selectInsurance = (sure: string) => {
    setInsuance(sure);
    stepperRef.current?.nextCallback();
  }
  useEffect(() => {

  }, []);


  return (

    <div className="card flex justify-content-center">
      <Stepper ref={stepperRef}>
        <StepperPanel header="Select insurance">
          <div className="flex-column h-12rem">
            <div className=" border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">

              <div className="w-full items-center text-center align-items-center">
                <div className="w-full flex items-center justify-center">
                  {/* sunshine */}
                  <div className="card bg-base-100 w-1/5 sm:w-full h-60 shadow-xl hover:shadow-orange-200 m-3" onClick={() => { selectInsurance("sunshine") }}>
                    <figure className="px-10 pt-10">
                      <img
                        src={`${apiUrlStatic}/static/media/sunshine-logo.png`}
                        alt="sunshine"
                        className="rounded-xl w-2/3" />
                    </figure>
                    <div className="card-body items-center text-center">
                      {/* <h2 className="card-title">sunshine</h2> */}
                      <p>AUTHORIZATION FORM</p>
                      <div className="card-actions">

                      </div>
                    </div>
                  </div>
                  {/* cigna */}
                  <div className="card bg-base-100 w-1/5 sm:w-full h-60 shadow-xl hover:shadow-orange-200 m-3" onClick={() => { selectInsurance("cigna") }}>
                    <figure className="px-10 pt-10">
                      <img
                        src={`${apiUrlStatic}/static/media/cigna.png`}
                        alt="cigna"
                        className="rounded-xl w-2/3" />
                    </figure>
                    <div className="card-body items-center text-center">
                      {/* <h2 className="card-title">Shoes!</h2> */}
                      <p>AUTHORIZATION FORM</p>
                      <div className="card-actions">

                      </div>
                    </div>
                  </div>
                  {/* Molina */}
                  <div className="card bg-base-100 w-1/5 sm:w-full h-60 shadow-xl hover:shadow-orange-200 m-3" onClick={() => { selectInsurance("molina") }}>
                    <figure className="px-10 pt-10">
                      <img
                        src={`${apiUrlStatic}/static/media/molinaLogo-notag.png`}
                        alt="Molina"
                        className="rounded-xl w-2/3" />
                    </figure>
                    <div className="card-body items-center text-center">

                      <p>AUTHORIZATION FORM</p>
                      <div className="card-actions">

                      </div>
                    </div>
                  </div>
                  {/* aetna */}
                  <div className="card bg-base-100 w-1/5 sm:w-full h-60 shadow-xl hover:shadow-orange-200 m-3" onClick={() => { selectInsurance("aetna") }}>
                    <figure className="px-10 pt-10">
                      <img
                        src={`${apiUrlStatic}/static/media/aetna.png`}
                        alt="aetna"
                        className="rounded-xl w-2/3" />
                    </figure>
                    <div className="card-body items-center text-center">
                      {/* <h2 className="card-title">Aetna</h2> */}
                      <div className="card-actions">
                        <p>AETNA MEDICAID <br /> AUTH FORM</p>

                      </div>
                    </div>
                  </div>
                  {/* aetna */}
                  <div className="card bg-base-100 w-1/5 sm:w-full h-60 shadow-xl hover:shadow-orange-200 m-3" onClick={() => { selectInsurance("aetnada") }}>
                    <figure className="px-10 pt-10">
                      <img
                        src={`${apiUrlStatic}/static/media/aetnada.png`}
                        alt="aetna"
                        className="rounded-xl w-2/3" />
                    </figure>
                    <div className="card-body items-center text-center">
                      <p>AETNA MEDICARE <br />AUTH FORM</p>
                      <div className="card-actions">

                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full flex items-center justify-center">
                  {/* WellCare */}
                  <div className="card bg-base-100 w-1/5 sm:w-full h-60 shadow-xl hover:shadow-orange-200 m-3" onClick={() => { selectInsurance("wellcare") }}>
                    <figure className="px-10 pt-10">
                      <img
                        src={`${apiUrlStatic}/static/media/wellcare.png`}
                        alt="wellcare"
                        className="rounded-xl w-16" />
                    </figure>
                    <div className="card-body items-center text-center">
                      {/* <h2 className="card-title">Wellcare</h2> */}
                      <p>AUTHORIZATION FORM</p>
                      <div className="card-actions">

                      </div>
                    </div>
                  </div>
                  {/* Simply */}
                  <div className="card bg-base-100 w-1/5 sm:w-full h-60 shadow-xl hover:shadow-orange-200 m-3" onClick={() => { selectInsurance("simply") }}>
                    <figure className="px-10 pt-10">
                      <img
                        src={`${apiUrlStatic}/static/media/simply.png`}
                        alt="Simply"
                        className="rounded-xl w-2/3" />
                    </figure>
                    <div className="card-body items-center text-center">
                      {/* <h2 className="card-title">Shoes!</h2> */}
                      <p>AUTHORIZATION FORM</p>
                      <div className="card-actions">

                      </div>
                    </div>
                  </div>
                  {/* Humana */}
                  <div className="card bg-base-100 w-1/5 sm:w-full h-60 shadow-xl hover:shadow-orange-200 m-3" onClick={() => { selectInsurance("humana") }}>
                    <figure className="px-10 pt-10">
                      <img
                        src={`${apiUrlStatic}/static/media/humana.png`}
                        alt="Humana"
                        className="rounded-xl w-16" />
                    </figure>
                    <div className="card-body items-center text-center">

                      <p>AUTHORIZATION FORM</p>
                      <div className="card-actions">

                      </div>
                    </div>
                  </div>
                  {/* Healthsun */}
                  <div className="card bg-base-100 w-1/5 sm:w-full h-60 shadow-xl hover:shadow-orange-200 m-3" onClick={() => { selectInsurance("healthsun") }}>
                    <figure className="px-10 pt-10">
                      <img
                        src={`${apiUrlStatic}/static/media/healthsun.png`}
                        alt="Healthsun"
                        className="rounded-xl w-2/3" />
                    </figure>
                    <div className="card-body items-center text-center">
                      {/* <h2 className="card-title">Aetna</h2> */}
                      <p>AUTHORIZATION FORM</p>
                      <div className="card-actions">

                      </div>
                    </div>
                  </div>
                  {/* careplus */}
                  <div className="card bg-base-100 w-1/5 sm:w-full h-60 shadow-xl hover:shadow-orange-200 m-3" onClick={() => { selectInsurance("careplus") }}>
                    <figure className="px-10 pt-10">
                      <img
                        src={`${apiUrlStatic}/static/media/careplus.png`}
                        alt="careplus"
                        className="rounded-xl w-2/3" />
                    </figure>
                    <div className="card-body items-center text-center">
                      <p>AUTHORIZATION <br />AUTH FORM</p>
                      <div className="card-actions">

                      </div>
                    </div>
                  </div>
                  {/* fcc */}
                  <div className="card bg-base-100 w-1/5 sm:w-full h-60 shadow-xl hover:shadow-orange-200 m-3" onClick={() => { selectInsurance("fcc") }}>
                    <figure className="px-10 pt-10">
                      <img
                        src={`${apiUrlStatic}/static/media/fcc.png`}
                        alt="fcc"
                        className="rounded-xl w-2/3" />
                    </figure>
                    <div className="card-body items-center text-center">
                      <p>AUTHORIZATION <br />AUTH FORM</p>
                      <div className="card-actions">

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex pt-4 justify-content-end">
            {/* <Button label="Next" icon="pi pi-arrow-right" iconPos="right"
              onClick={() => { stepperRef.current?.nextCallback() }}
            /> */}
          </div>
        </StepperPanel>
        <StepperPanel header="Required Files">
          <div className="flex flex-column h-12rem">
            <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
              <div className="w-1/5">
                <Menu model={items} className="w-full" />
              </div>
              <div className="w-4/5 border-l-2 border-dashed surface-border border-round surface-ground p-5">
                {fileSelect === "form" && <>
                  {insuance === "sunshine" && <SunshineA scm={scm} />}
                  {insuance === "cigna" && <CignaA />}
                  {insuance === "molina" && <Molina scm={scm} />}
                  {insuance === "aetna" && <AetnaBHA scm={scm} />}
                  {insuance === "aetnada" && <AetnaDA />}
                  {/* row2 */}
                  {insuance === "wellcare" && <WellcareA scm={scm} />}
                  {insuance === "simply" && <Carelon scm={scm} />}
                  {insuance === "humana" && <Carelon scm={scm} />}
                  {insuance === "healthsun" && <Carelon scm={scm} />}
                  {insuance === "careplus" && <Carelon scm={scm} />}
                  {insuance === "fcc" && <FCC scm={scm} />}
                </>}
                {fileSelect === "certification" && <Certification active={active} relad={relad} scm={scm} />}
                {fileSelect === "assessment" && <Assessment relad={relad} scm={scm} />}
                {fileSelect === "sp" && <Sp relad={relad} scm={scm} view={"View"} />}
              </div>

            </div>
          </div>
          <div className="flex pt-4 justify-content-between">
            <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
            <Button label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />
          </div>
        </StepperPanel>
        <StepperPanel header="Authorization information">
          <div className="flex flex-column h-12rem">
            <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">Content III</div>
          </div>
          <div className="flex pt-4 justify-content-start">
            <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
          </div>
        </StepperPanel>
      </Stepper>
    </div>
  );
};
type Props = {
  active?: Active;
  scm: ServiceCM | undefined;
  relad(): void;
};
