import React, { useState, useEffect } from 'react';
import { OrganizationChart } from 'primereact/organizationchart';
import { TreeNode } from 'primereact/treenode';
// -- New Struct
import { Active } from "../../../../models";
import { useCoreTCMS } from "../../hooks";

const Tree = ({ active, relad }: Props) => {
    const { tcms, reloadTCMS } = useCoreTCMS();
    const [data, setData] = useState<TreeNode[]>([{
        label: 'SunissUp',
        expanded: true,
    }]);

    useEffect(() => {
        const newData = [{
            label: "SunissUp",
            expanded: true,
            children: [{
                label: "QA",
                expanded: true,
                children: tcms?.tcms?.map(tcm => ({
                    // label: `TCMS - ${tcm.info?.Record?.fullname}`,
                    expanded: true,
                    type: 'person',
                        data: {
                            name: tcm.info?.Record?.fullname,
                            title: `TCMS - ${tcm.info?.User?.credentials}`
                        },
                    children: tcm.list_tcm?.map(tcmLit => ({
                        // label: `TCM - ${tcmLit.info?.Record?.fullname}`,
                        expanded: false,
                        type: 'person',
                        data: {
                            name: tcmLit.info?.Record?.fullname,
                            title: `TCM - ${tcmLit.info?.User?.credentials}`
                        },
                        children: tcmLit.clients?.map(clients => ({
                            // label: "",
                            style: { 
                                // backgroundColor: `#${clients.id}`,
                                borderRadius: '12px',
                                
                            },
                            type: 'person',
                            data: {
                                name: clients.first_name,
                                mr: clients.id,
                                // title: 'Client'
                            },
                        })),
                    })),
                })) || []
            }]
        }];
        setData(newData);
    }, [tcms]);

    const nodeTemplate = (node) => {
        if (node.type === 'person') {
            return (
                <div className="flex flex-column">
                    <div className=" align-items-center">
                        <i className="pi pi-user" style={{ fontSize: '2rem' }}></i>
                        {/* <img alt={node.data.name} src={node.data.image} className="mb-3 w-3rem h-3rem" /> */}
                        <br />
                        <span className="font-bold mb-2">{node.data.name}</span>
                        <br />
                        { node.data.title && <span>{node.data.title}</span>}
                        { node.data.mr && <span><b>MR: </b>{node.data.mr}</span>}
                    </div>
                </div>
            );
        }

        return node.label;
    };

    return (
        <div className="card w-full pt-5">
            <OrganizationChart value={data} nodeTemplate={nodeTemplate} />
        </div>
    );
}
type Props = {
    active?: Active;
    relad(): void;
};
export { Tree }