import { Routes, Route } from "react-router-dom";
import { NotFoundView } from "./views/components";
import { ProfileView } from "./views/GlobalView";
import { Logout } from "./modules/auth";
import { Active } from "./models";

const RoutesContainer: React.FC = ({ active }: Props) => {
  return (
    <Routes>
      <Route path="/" element={<ProfileView />} />
      <Route path="logout" element={<Logout />} />
      <Route path="*" element={<NotFoundView />} />
    </Routes>
  );
};
type Props = {
  active?: Active;
};
export { RoutesContainer };
